import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTools, FaExchangeAlt, FaHandshake, FaCloud, FaComments, FaDatabase, FaServer, FaHeadset, FaCogs, FaUserCheck, FaDollarSign, FaUserClock, FaLayerGroup, FaShieldAlt, FaLaptop, FaUserFriends, FaLock, FaBriefcase, } from 'react-icons/fa';



export default function SmallBusiness() {

    const SmallBusinesHero = () => {
        const { scrollY } = useScroll();
        const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
        const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
        const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
        const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

        return (
            <div className="relative h-[500px] md:h-[600px] bg-[url('https://t4.ftcdn.net/jpg/03/08/69/75/360_F_308697506_9dsBYHXm9FwuW0qcEqimAEXUvzTwfzwe.jpg')] bg-center bg-no-repeat bg-cover overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-gray-100 text-center px-4 md:px-6">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Small Business Hosting with <span className='text-blue-600 font-bold'>ConciseNext</span>
                    </motion.h1>
                    <motion.p
                        className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        Boost your business with ConciseNext’s Small Business Hosting, offering secure remote access for seamless collaboration. Our scalable solutions simplify operations and drive efficiency.
                    </motion.p>
                    <motion.a
                        href="/free-trial"
                        className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
                    >
                        Learn More
                    </motion.a>
                </div>

                {/* Parallax Image */}
                {/* <motion.img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf0J11mGCr4qq2ijT_M-BkcRpwBC9Hw_JKGP3_gcRakZAv1oSAWrooX9aS9uVTUX9gFF4&usqp=CAU"
                    alt="QuickBooks"
                    className="absolute bottom-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 right-0 transform translate-y-8 md:translate-y-16 sm:translate-y-0"
                    style={{ y: yImage }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                /> */}
            </div>
        );
    };



    const IntroSection = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView && window.innerWidth > 768) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Small Business Hosting - ConciseNext
                </h2>

                <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
                    <img
                        src="https://usa.visa.com/dam/VCOM/global/run-your-business/images/sbh-images/tea-shop-owner-1600x900.jpeg"
                        alt="QuickBooks License"
                        className="rounded-lg shadow-lg w-[400px] h-[auto]"
                    />
                    <p className="text-lg text-gray-700 leading-relaxed text-center md:text-left">
                        Empower your small business with ConciseNext’s Small Business Hosting solutions. Whether you are running an accounting firm, a retail store, or a service-based company, our cloud hosting services provide the flexibility, security, and performance you need to succeed. We offer scalable hosting environments designed to meet the unique needs of small businesses, giving you the tools to manage your software and data remotely, streamline operations, and grow your business with confidence.
                    </p>
                </div>

                <h3 className="text-3xl pt-8 font-bold text-gray-800 text-center md:text-left">
                    Why Choose ConciseNext for Small Business Hosting?
                </h3>

                <ul className="space-y-8">
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaLaptop className="w-8 h-8 text-blue-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Remote Access</h4>
                            <p className="text-lg text-gray-700">
                                Manage your business from anywhere, at any time. With our cloud hosting services, you can securely access your business software, files, and data from any device, allowing you to stay connected whether you're in the office, at home, or on the go.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaUserFriends className="w-8 h-8 text-green-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Scalability</h4>
                            <p className="text-lg text-gray-700">
                                As your business grows, your hosting needs will evolve. ConciseNext offers scalable hosting solutions that grow with your business, allowing you to add more users, resources, or software as needed, without the hassle of upgrading your physical infrastructure.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaShieldAlt className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Security</h4>
                            <p className="text-lg text-gray-700">
                                Protect your sensitive business data with ConciseNext’s enterprise-grade security measures. Our hosting environment is secured with firewalls, encryption, multi-factor authentication, and regular security audits, ensuring your data is safe from cyber threats.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaDollarSign className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cost-Efficiency</h4>
                            <p className="text-lg text-gray-700">
                                Save on IT infrastructure costs with ConciseNext’s cloud hosting. We take care of server maintenance, software updates, security patches, and backups, freeing you from the burden of managing expensive hardware and complex IT systems.
                            </p>
                        </div>
                    </li>

                </ul>
            </motion.div>
        );
    };

    const Benefits = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto pt-8 p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Key Benefits of Small Business Hosting with ConciseNext
                </h2>

                {/* License Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
                    {[
                        {
                            title: '24/7 Access to Business Tools',
                            description:
                                'Keep your business running smoothly by accessing your software, applications, and data whenever you need them. Our hosting solution offers you the flexibility to work from any location with internet access.',
                            imageUrl:
                                'https://concisenext.com/public_image/960x0.webp',
                            link: '/quickbooks-pro-hosting',
                        },
                        {
                            title: 'Collaborative Work Environment',
                            description:
                                'Enable your team to collaborate in real-time with shared access to applications and files. Whether you have employees working remotely or in different locations, ConciseNext makes collaboration seamless and efficient.',
                            imageUrl:
                                'https://concisenext.com/public_image/hr-learn20-gpstrategies-thumb.webp',
                            link: '#',
                        },

                        {
                            title: 'Expert Support',
                            description:
                                'Our dedicated support team is available 24/7 to help with any technical issues, ensuring that your hosting environment runs smoothly without interruptions. Whether you have a small question or a critical issue, we’re here to assist you.',
                            imageUrl:
                                'https://concisenext.com/public_image/best-online-tech-support-services.webp',
                            link: '#',
                        },
                        {
                            title: '99.9% Uptime Guarantee',
                            description:
                                'Rely on our cloud infrastructure to keep your business operational. We ensure high availability and minimal downtime, so your team can stay productive even during peak periods.',
                            imageUrl:
                                'https://concisenext.com/public_image/technology-driven-workplace-transformation-and-how-to-go-about-it.webp',
                            link: '#',
                        },
                    ].map((license, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            className="bg-white rounded-lg shadow-lg p-5 flex flex-col items-center text-start space-y-4 transition-transform duration-300"
                        >
                            <img
                                src={license.imageUrl}
                                alt={license.title}
                                className="rounded-lg shadow-lg w-full h-48 object-cover"
                            />
                            <h4 className="text-xl font-semibold text-gray-800">
                                {license.title}
                            </h4>
                            <p className="text-gray-600 pb-10">{license.description}</p>

                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };


    const SmallBusinessSolutions = () => {
        const controls = useAnimation();
        const { ref, inView } = useInView({ threshold: 0.2 });

        React.useEffect(() => {
            if (inView) {
                controls.start('visible');
            } else {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const fadeInVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
        };
        const isLargeScreen = window.innerWidth >= 768;

        return (
            <div className="bg-gray-100 text-gray-800 py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold text-center mb-12">Industry-Leading Solutions for Small Businesses</h2>

                    <div ref={ref} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-blue-500"
                            initial="hidden"
                            animate={isLargeScreen ? controls : 'visible'}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaCloud className="text-blue-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Accounting and Tax Firms</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Seamlessly host your accounting and tax software in the cloud, including QuickBooks, Sage, Drake, ProSeries, and more. Access your accounting data from anywhere, collaborate with clients, and ensure data security with our comprehensive cloud hosting services.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 hover:bg-red-700 hover:text-white transition-colors rounded-md">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-green-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaServer className="text-green-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Retail and eCommerce</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Manage your business software, inventory, customer data, and POS systems securely in the cloud. With ConciseNext’s Small Business Hosting, you can enhance your operational efficiency while offering a seamless customer experience.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-md hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-red-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaDatabase className="text-red-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Service-Based Businesses</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Whether you provide professional services, consulting, or IT support, ConciseNext offers cloud hosting solutions that allow you to work from any location, manage customer data securely, and streamline your business operations.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-lg hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>
                    </div>
                </div>
            </div>
        );
    };



    const HostingWorks = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-6xl mx-auto p-8 pt-8 space-y-12 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    How ConciseNext Small Business Hosting Works
                </h2>

                <div className="relative">
                    <div className="absolute left-1/2 top-0 transform -translate-x-1/2 h-full border-l-2 border-dashed border-gray-300" />
                    <div className="space-y-8">
                        {[
                            {
                                step: 'Consultation',
                                description: 'Our team assesses your business needs to recommend the best hosting plan for your software and data requirements.',
                                icon: <FaComments className="w-10 h-10 text-blue-500" />,
                            },
                            {
                                step: 'Migration',
                                description: 'We handle the migration of your existing software and data to the cloud, ensuring a seamless transition with minimal downtime.',
                                icon: <FaExchangeAlt className="w-10 h-10 text-green-500" />,
                            },
                            {
                                step: 'Cloud Access',
                                description: 'Once your business is hosted in our cloud environment, you and your team can securely access applications and files from any device with an internet connection.',
                                icon: <FaCloud className="w-10 h-10 text-purple-500" />,
                            },
                            {
                                step: 'Ongoing Management',
                                description: 'We take care of all IT management, including backups, updates, security, and troubleshooting, allowing you to focus on growing your business without worrying about technology issues.',

                                icon: <FaHeadset className="w-10 h-10 text-red-500" />,
                            },
                        ].map((step, index) => (
                            <div key={index} className="relative bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center space-y-4">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 flex items-center justify-center bg-white rounded-full border-4 border-gray-200">
                                    {step.icon}
                                </div>
                                <div className="mt-12">
                                    <h3 className="text-2xl font-semibold text-gray-800">{step.step}</h3>
                                    <p className="text-lg text-gray-600 mt-2">{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        );
    };


    const WhyChooseConciseNext = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const cardVariants = {
            hidden: { opacity: 0, y: 10 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.6,
                    ease: "easeOut",
                    when: "beforeChildren",
                    staggerChildren: 0.3,
                },
            },
        };

        const iconVariants = {
            hidden: { scale: 0 },
            visible: {
                scale: 1,
                transition: {
                    duration: 0.5,
                    ease: "backOut",
                },
            },
        };
        const isLargeScreen = window.innerWidth >= 768;
        return (
            <div className="bg-gray-50 py-5">
                <div className="max-w-7xl mx-auto px-6 sm:px-8">
                    <motion.h2
                        className="text-4xl font-bold text-center text-gray-800 mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Why Small Businesses Trust ConciseNext
                    </motion.h2>

                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={isLargeScreen ? controls : 'visible'} s
                        variants={cardVariants}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8"
                    >
                        {[
                            {
                                title: "Experienced Hosting Provider",
                                description:
                                    "With years of experience in cloud hosting, ConciseNext understands the challenges faced by small businesses. Our hosting solutions are designed to be cost-effective, scalable, and secure, ensuring that your business has the technology it needs to succeed.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-blue-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaShieldAlt size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Cost Savings",
                                description:
                                    "By hosting your business applications in the cloud, you eliminate the need for costly on-premise servers, maintenance, and IT staff. Our cloud hosting services provide the same level of functionality and security at a fraction of the cost.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-green-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaDollarSign size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Secure, Reliable Hosting",
                                description:
                                    "Data security is critical for small businesses, and we take it seriously. Our hosting solutions are built with the highest security standards, ensuring your sensitive business data is always protected.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaTools size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Scalable Solutions",
                                description:
                                    "As your business grows, our cloud solutions grow with you. Easily scale your hosting plan to add more users, applications, or data storage as your business needs evolve.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaTools size={48} />
                                    </motion.div>
                                ),
                            },
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-lg shadow-lg text-start flex flex-col items-center"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    };


    const HostingSolutions = () => {
        const solutions = [
            {
                icon: <FaCloud className="text-blue-600 w-14 h-14" />,
                title: "Small Business Cloud Hosting",
                description: "Secure and scalable cloud solutions for businesses of all sizes, enabling you to manage software and data with ease.",
            },
            {
                icon: <FaLock className="text-green-600 w-14 h-14" />,
                title: "QuickBooks Hosting",
                description: "Access your QuickBooks software from anywhere, collaborate with your team in real-time, and ensure the security of your financial data.",
            },
            {
                icon: <FaBriefcase className="text-purple-600 w-14 h-14" />,
                title: "Sage Hosting",
                description: "Optimize your business operations by hosting Sage software in the cloud. Enjoy flexibility, enhanced collaboration, and better resource management.",
            },
            {
                icon: <FaTools className="text-red-600 w-14 h-14" />,
                title: "Custom Hosting Solutions",
                description: "ConciseNext can create a custom hosting environment tailored to your unique business needs.",
            },
        ];

        return (
            <section className="bg-gray-100 py-8">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
                        Hosting Solutions Tailored for Small Businesses
                    </h2>

                    <div className="space-y-8">
                        {solutions.map((solution, index) => (
                            <AnimatedCard
                                key={index}
                                icon={solution.icon}
                                title={solution.title}
                                description={solution.description}
                                delay={index * 0.2}
                            />
                        ))}
                    </div>
                </div>
            </section>
        );
    };

    const AnimatedCard = ({ icon, title, description, delay }) => {
        const [ref, inView] = useInView({
            triggerOnce: true,
            threshold: 0.2,
        });

        const cardVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
        };

        // Determine if animation should be applied based on screen size
        const isLargeScreen = window.innerWidth > 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={inView && isLargeScreen ? "visible" : "hidden"}
                variants={isLargeScreen ? cardVariants : {}}
                transition={{ duration: 0.4, delay }}
                className="flex items-center bg-white shadow-lg rounded-lg p-6 hover:bg-blue-50 transition-all"
            >
                <div className="mr-6">
                    {icon}
                </div>
                <div>
                    <h3 className="text-2xl font-semibold text-gray-700 mb-2">
                        {title}
                    </h3>
                    <p className="text-gray-600">
                        {description}
                    </p>
                </div>
            </motion.div>
        );
    };




    const SoftwareVersion = () => {
        const containerVariants = {
            hidden: { opacity: 0, y: 20 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    staggerChildren: 0.3,
                    duration: 0.5,
                    ease: 'easeInOut',
                },
            },
        };

        const itemVariants = {
            hidden: { opacity: 0, y: 10 },
            visible: { opacity: 1, y: 0 },
        };

        const softwareList = [
            { name: 'Drake Tax Software', icon: FaDatabase },
            { name: 'Lacerte Tax Software', icon: FaCloud },
            { name: 'ProSeries Tax Software', icon: FaLock },
            { name: 'UltraTax CS', icon: FaTools },
            { name: 'ProSystem fx', icon: FaCloud },
        ];

        return (
            <div className="relative bg-blue-50 py-16 px-6">
                {/* Background Image */}
                <div className="absolute inset-0 bg-[url('https://concisenext.com/public_image/ai-generated-8799924_1280.webp')] bg-cover bg-center opacity-20"></div>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                    className="relative max-w-6xl mx-auto text-center bg-white p-8 rounded-lg shadow-lg"
                >
                    {/* Title Section */}

                    {/* Divider */}
                    <div className="mt-12">
                        <hr className="border-blue-300 mb-8" />

                        {/* TaxWise Hosting Section with Image */}
                        <motion.div variants={containerVariants} className="text-left">
                            <motion.h3
                                variants={itemVariants}
                                className="text-3xl font-bold text-blue-900 mb-4"
                            >
                                Elevate Your Small Business with ConciseNext’s Cloud Hosting
                            </motion.h3>
                            <div className="flex flex-col md:flex-row md:items-center">
                                <motion.p
                                    variants={itemVariants}
                                    className="text-lg text-blue-700 leading-relaxed md:w-1/2"
                                >
                                    Get the flexibility, security, and scalability your business needs to thrive in a competitive market. ConciseNext’s Small Business Hosting solutions give you the freedom to focus on what matters most – growing your business. Let us handle your cloud hosting needs so you can work smarter, not harder.
                                </motion.p>
                                {/* Image with transition */}
                                <motion.img
                                    src="https://concisenext.com/public_image/ai-generated-8799924_1280.webp"
                                    alt="TaxWise Hosting"
                                    className="rounded-lg shadow-lg w-full md:w-1/2 mt-6 md:mt-0 md:ml-8"
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5 }}
                                />
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        );
    };


    const ContactUs = () => {
        return (
            <section className="relative bg-gray-100 py-6 px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="absolute inset-0">
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 translate-y-1/4 -z-10" width="404" height="404" fill="none" viewBox="0 0 404 404">
                        <defs>
                            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-indigo-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#pattern1)" />
                    </svg>
                </div>
                <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <motion.h2
                        className="text-3xl font-extrabold text-gray-900 mb-6"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        Contact Us
                    </motion.h2>
                    <div className="flex flex-col items-center">
                        <motion.div
                            className="text-gray-800 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
                        >
                            <p className="text-lg font-semibold">📞 Call us: +1 (800) 123-4567</p>
                            <p className="text-lg font-semibold">📧 Email us: <a href="mailto:sales@ConciseNext.com" className="text-indigo-600 hover:underline">sales@ConciseNext.com</a></p>
                        </motion.div>
                        <motion.p
                            className="text-gray-500"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
                        >
                            ConciseNext – Your trusted partner in small business cloud hosting.
                        </motion.p>
                    </div>
                </div>
            </section>
        );
    };

    useEffect(() => {
        // Set the document title
        document.title = "Small Business Software Cloud Hosting || ConciseNext.com";

        // Create and set meta tags
        const metaDescription = document.createElement('meta');
        metaDescription.name = "description";
        metaDescription.content = "Boost your business with ConciseNext’s Small Business Hosting, offering secure remote access for seamless collaboration";
        document.head.appendChild(metaDescription);

        const metaKeywords = document.createElement('meta');
        metaKeywords.name = "keywords";
        metaKeywords.content = "concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management";
        document.head.appendChild(metaKeywords);

        const metaRobots = document.createElement('meta');
        metaRobots.name = "robots";
        metaRobots.content = "index, follow";
        document.head.appendChild(metaRobots);

        // Set the canonical link
        const canonicalLink = document.createElement('link');
        canonicalLink.rel = "canonical";
        canonicalLink.href = document.URL; // Adjust to the specific page
        document.head.appendChild(canonicalLink);

        // Open Graph tags
        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute("property", "og:title");
        ogTitle.content = "ConciseNext: Your Cloud Application Hosting Solution"; // Title reflecting your service
        document.head.appendChild(ogTitle);

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute("property", "og:description");
        ogDescription.content = "Discover seamless cloud application hosting for QuickBooks, Sage, Tax Software, Drake, and more. Reliable, secure, and tailored for your business needs.";
        document.head.appendChild(ogDescription);

        const ogImage = document.createElement('meta');
        ogImage.setAttribute("property", "og:image");
        ogImage.content = "https://concisenext.com/static/media/logocolor.481479ea418411f3e450.png"; // Replace with actual image URL
        document.head.appendChild(ogImage);

        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute("property", "og:url");
        ogUrl.content = document.URL; // Adjust to the specific page
        document.head.appendChild(ogUrl);


        // Cleanup function to remove meta tags on unmount
        return () => {
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);
            document.head.removeChild(canonicalLink);
            document.head.removeChild(ogTitle);
            document.head.removeChild(ogDescription);
            document.head.removeChild(ogImage);
            document.head.removeChild(ogUrl);
        };
    }, [])


    return (
        <div>
            <SmallBusinesHero />
            <IntroSection />
            <Benefits />
            <SmallBusinessSolutions />
            <HostingWorks />
            <HostingSolutions />
            <WhyChooseConciseNext />
            <SoftwareVersion />
            <ContactUs />


        </div>
    )

}