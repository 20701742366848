// src/components/QueryForm.jsx
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const QueryForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        countryCode: '+1',
        phone: '',
        query: '',
    });
    const [isSending, setIsSending] = useState(false);
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSending(true);

        emailjs.send(
            'service_bp00fd6',  // Replace with your EmailJS service ID
            'template_9yljnxu', // Replace with your EmailJS template ID
            formData,
            'dKZYWeqy3LxuG5n_D'      // Replace with your EmailJS user ID
        )
            .then((response) => {
                console.log('SUCCESS:', response);
                setMessage('Your query has been submitted successfully.');
                setFormData({
                    name: '',
                    email: '',
                    countryCode: '+1',
                    phone: '',
                    query: '',
                });
            })
            .catch((error) => {
                console.log('FAILED:', error);
                setMessage('There was an error submitting your query. Please try again.');
            })
            .finally(() => {
                setIsSending(false);
            });
    };

    return (
        <div className="p-8 bg-gray-100">
            <h2 className="text-4xl font-extrabold text-[#35436a] mb-6">Drop Your Query</h2>
            {message && <p className="mb-4 text-lg font-semibold">{message}</p>}
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="relative">
                    <label htmlFor="name" className="block text-lg font-semibold text-gray-800">
                        Enter Your Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                        placeholder="Your Name"
                        required
                    />
                </div>

                <div className="relative">
                    <label htmlFor="email" className="block text-lg font-semibold text-gray-800">
                        Enter Email ID
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                        placeholder="yourname@example.com"
                        required
                    />
                </div>

                <div className="relative">
                    <label htmlFor="phone" className="block text-lg font-semibold text-gray-800">
                        Phone Number
                    </label>
                    <div className="mt-2 flex">
                        <select
                            id="countryCode"
                            name="countryCode"
                            value={formData.countryCode}
                            onChange={handleChange}
                            className="p-3 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-gray-100 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                        >
                            <option value="+1">+1 (USA)</option>
                            <option value="+44">+44 (UK)</option>
                            <option value="+91">+91 (India)</option>
                            <option value="+61">+61 (Australia)</option>
                            <option value="+81">+81 (Japan)</option>
                        </select>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="p-3 w-full border border-gray-300 rounded-r-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                            placeholder="Your Phone Number"
                            required
                        />
                    </div>
                </div>

                <div className="relative">
                    <label htmlFor="query" className="block text-lg font-semibold text-gray-800">
                        Drop Your Query
                    </label>
                    <textarea
                        id="query"
                        name="query"
                        value={formData.query}
                        onChange={handleChange}
                        className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                        rows="5"
                        placeholder="Describe your issue or question..."
                        required
                    ></textarea>
                </div>

                <button
                    type="submit"
                    className="w-full py-3 bg-[#35436a] text-white font-bold rounded-md hover:bg-[#1a85cf] transition-colors duration-300 ease-in-out hover:shadow-lg"
                    disabled={isSending}
                >
                    {isSending ? 'Submitting...' : 'Submit Query'}
                </button>
            </form>
        </div>
    );
};

export default QueryForm;
