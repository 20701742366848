import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock } from "react-icons/fa";
import { FiPhone, FiMail, FiHeadphones } from 'react-icons/fi';
import logo from "./../assets/images/logo/logocolor.png";
import { useEffect } from "react";
import QueryForm from "./QueryForm";


const ContactUs = () => {


  useEffect(() => {
    // Set the document title
    document.title = "Contact Us || ConciseNext.com";

    // Create and set meta tags
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Join countless other businesses that have transformed their operations with our reliable cloud hosting services";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management";
    document.head.appendChild(metaKeywords);

    const metaRobots = document.createElement('meta');
    metaRobots.name = "robots";
    metaRobots.content = "index, follow";
    document.head.appendChild(metaRobots);

    // Set the canonical link
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = "canonical";
    canonicalLink.href = document.URL; // Adjust to the specific page
    document.head.appendChild(canonicalLink);

    // Open Graph tags
    const ogTitle = document.createElement('meta');
    ogTitle.setAttribute("property", "og:title");
    ogTitle.content = "ConciseNext: Your Cloud Application Hosting Solution"; // Title reflecting your service
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.setAttribute("property", "og:description");
    ogDescription.content = "Discover seamless cloud application hosting for QuickBooks, Sage, Tax Software, Drake, and more. Reliable, secure, and tailored for your business needs.";
    document.head.appendChild(ogDescription);

    const ogImage = document.createElement('meta');
    ogImage.setAttribute("property", "og:image");
    ogImage.content = "https://concisenext.com/static/media/logocolor.481479ea418411f3e450.png"; // Replace with actual image URL
    document.head.appendChild(ogImage);

    const ogUrl = document.createElement('meta');
    ogUrl.setAttribute("property", "og:url");
    ogUrl.content = document.URL; // Adjust to the specific page
    document.head.appendChild(ogUrl);


    // Cleanup function to remove meta tags on unmount
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
      document.head.removeChild(metaRobots);
      document.head.removeChild(canonicalLink);
      document.head.removeChild(ogTitle);
      document.head.removeChild(ogDescription);
      document.head.removeChild(ogImage);
      document.head.removeChild(ogUrl);
    };
  }, [])

  return (
    <div className="min-h-screen  text-black flex flex-col justify-center items-center  py-16">



      {/* Header Section */}
      <section className="text-center mb-16 mt-24 xl:px-32 ">

        <h1 className="text-5xl font-bold text-black mb-4">Contact Us</h1>
        <p className="text-lg  ">
          We’re here to make your QuickBooks hosting experience seamless and worry-free. Whether you need more information about our services, have questions about our pricing, or require technical support, our team is always ready to assist you.
        </p>
      </section>

      {/* Contact Info Cards */}
      <section className="grid grid-cols-1 md:grid-cols-3 gap-12 max-w-6xl w-full">
        {/* Phone Card */}
        <div className="bg-gradient-to-br from-blue-800 to-blue-900 shadow-lg rounded-lg p-8 text-center transform hover:scale-105 transition-transform duration-300">
          <FaPhone className="text-yellow-400 text-4xl mb-4" />
          <h3 className="text-xl font-semibold text-white mb-2">Call Us</h3>
          <p className="text-lg text-white">
  <a href="tel:+18001234567" className="text-white">📞 +1 (800) 123-4567</a>
</p>
          <p className="text-sm text-start text-white mt-2">
            Our knowledgeable customer service team is just a phone call away. Whether it’s a quick inquiry or a detailed discussion about how our QuickBooks cloud hosting services can help your business, we’re available during business hours to assist you
          </p>
        </div>

        {/* Email Card */}
        <div className="bg-gradient-to-br from-blue-800 to-blue-900 shadow-lg rounded-lg p-8 text-center transform hover:scale-105 transition-transform duration-300">
          <FaEnvelope className="text-yellow-400 text-4xl mb-4" />
          <h3 className="text-xl font-semibold text-white mb-2">Email Us</h3>
          <p className="text-lg text-white">
  <a href="mailto:support@concisenext.com" className="text-white">📧 support@concisenext.com</a>
</p>
<p className="text-lg text-white">
  <a href="mailto:sales@concisenext.com" className="text-white">📧 sales@concisenext.com</a>
</p>
          <p className="text-sm mt-2 text-start text-white">
            Prefer email? Drop us a line anytime, and we’ll respond within 24 hours. Our team is dedicated to addressing all your concerns swiftly and effectively..
          </p>
        </div>

        {/* Address Card */}
        <div className="bg-gradient-to-br from-blue-800 to-blue-900 shadow-lg rounded-lg p-8 text-center transform hover:scale-105 transition-transform duration-300">
          <FaMapMarkerAlt className="text-yellow-400 text-4xl mb-4" />
          <h3 className="text-xl font-semibold text-white mb-2">Visit Us</h3>
          <p className="text-lg text-white">🏢 123 Cloud Hosting Blvd, Suite 500</p>
          <p className="text-lg text-white">City, State, ZIP</p>
          <p className="text-sm text-start mt-2 text-white">
            Want to visit us? Feel free to stop by our headquarters during business hours or schedule an appointment for a one-on-one consultation.
          </p>
        </div>
      </section>

      {/* Business Hours Section */}
      <section className="mt-12 w-full text-center">
        <div className="mb-6">
          <img
            src={logo}
            alt="Company Logo"
            className="mx-auto h-100 w-[auto]" // Centers the logo horizontally
          />
        </div>
        <h2 className="text-3xl font-semibold text-black mb-4">Business Hours</h2>
        <p className="text-lg mb-2">Monday - Friday: 9:00 AM - 6:00 PM (EST)</p>
        <p className="text-lg  mb-2">Saturday: 10:00 AM - 4:00 PM (EST)</p>
        <p className="text-lg  mb-2">Sunday: Closed</p>
        <p className="text-sm mt-2">
          Want to visit us? Feel free to stop by our headquarters during business hours or schedule an appointment for a one-on-one consultation.
        </p>
      </section>

      <section>
        <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto text-center">
            {/* Let us help you section */}
            <div className="mb-12">
              <h2 className="text-4xl font-extrabold text-gray-900 mb-4">Let Us Help You</h2>
              <p className="text-gray-600 text-lg max-w-2xl mx-auto mb-6">
                Need more details about how ConciseNext can help your business with QuickBooks hosting? We offer a variety of hosting options, including QuickBooks Enterprise hosting, QuickBooks Pro hosting, and QuickBooks Premier hosting. Reach out to learn about the best solution for your specific needs.
              </p>
            </div>

            {/* Quick Support for Existing Customers */}
            <div className="grid gap-8 md:grid-cols-2">
              <div className="bg-gray-100 p-6 shadow-lg rounded-lg">
                <FiHeadphones className="text-blue-500 text-3xl mb-4 mx-auto" />
                <h3 className="text-xl font-semibold text-gray-700 mb-2">Quick Support for Existing Customers</h3>
                <p className="text-gray-600 mb-4">
                  If you’re already part of the ConciseNext family, our technical support team is always on hand to resolve any issues.
                </p>
                <p className="text-gray-500">
                  We know how critical uptime and seamless service are to your business. Reach out to us by phone or email, and we’ll have your concerns addressed promptly.
                </p>
              </div>

              {/* Contact Details */}
              <div className="bg-gray-100 p-6 shadow-lg rounded-lg">
                <div className="flex items-center justify-center mb-4">
                  <FiPhone className="text-blue-500 text-2xl mr-2" />
                  <span className="text-lg text-gray-700">📞 +1 (800) 123-4567</span>
                </div>
                <div className="flex items-center justify-center mb-4">
                  <FiMail className="text-blue-500 text-2xl mr-2" />
                  <span className="text-lg text-gray-700">
                    📧 <a href="mailto:support@ConciseNext.com" className="hover:text-blue-500">support@ConciseNext.com</a>
                  </span>
                </div>
                <p className="text-gray-500 text-center mt-2">
                  We’ll address your concern promptly to ensure uninterrupted service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      {/* <div className="lg:w-1/2 p-8 my-10 bg-gray-100">
        <h2 className="text-4xl font-extrabold text-[#1a85cf] mb-6">Contact Form</h2>
        <p className="text-sm mb-10">Have a question or need assistance? Fill out the form below, and one of our team members will reach out to you shortly:</p>
        <form className="space-y-6">
          <div className="relative">
            <label htmlFor="name" className="block text-lg font-semibold text-gray-800">
              Enter Your Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
              placeholder="Your Name"
              required
            />
          </div>

          <div className="relative">
            <label htmlFor="email" className="block text-lg font-semibold text-gray-800">
              Enter Email ID
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
              placeholder="yourname@example.com"
              required
            />
          </div>

          <div className="relative">
            <label htmlFor="phone" className="block text-lg font-semibold text-gray-800">
              Phone Number
            </label>
            <div className="mt-2 flex">
              <select
                id="countryCode"
                name="countryCode"
                className="p-3 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-gray-100 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                required
              >
                <option value="+1">+1 (USA)</option>
                <option value="+44">+44 (UK)</option>
                <option value="+91">+91 (India)</option>
                <option value="+61">+61 (Australia)</option>
                <option value="+81">+81 (Japan)</option>
              </select>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="p-3 w-full border border-gray-300 rounded-r-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                placeholder="Your Phone Number"
                required
              />
            </div>
          </div>

          <div className="relative">
            <label htmlFor="query" className="block text-lg font-semibold text-gray-800">
              Drop Your Query
            </label>
            <textarea
              id="query"
              name="query"
              className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
              rows="5"
              placeholder="Describe your issue or question..."
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full py-3 bg-[#1a85cf] text-white font-bold rounded-md hover:bg-blue-700 transition-colors duration-300 ease-in-out hover:shadow-lg"
          >
            Submit Query
          </button>
        </form>
      </div> */}
      <div className="lg:w-1/2">
      <QueryForm />
      </div>

    </div>
  );
};

export default ContactUs;
