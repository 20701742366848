import React, { useEffect, useRef } from "react";
import { Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { FiArrowRight } from "react-icons/fi";
import { Link } from 'react-router-dom'
import { Carousel } from "flowbite-react";
import { FaLock, FaShieldAlt, FaUserShield, FaGlobe, FaCloud, FaSyncAlt, FaLockOpen, FaDatabase } from 'react-icons/fa';
import {
  useMotionTemplate,
  useMotionValue,
  motion,
  animate,
  useScroll,
  useTransform,
} from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import Navbar from "../components/Navbar/Navbar";
import BgVideo from "../assets/videos/homeVideo.mp4";
import BgVideoWebM from "../assets/videos/homeVideo.webm";
import quickbooksImage from '../assets/images/quickbook.webp';
import sageImage from '../assets/images/saeg.webp';
import QueryForm from "./QueryForm";

const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

export default function HomePage() {
  const color = useMotionValue(COLORS_TOP[0]);
  useEffect(() => {
    animate(color, COLORS_TOP, {
      ease: "easeInOut",
      duration: 10,
      repeat: Infinity,
      repeatType: "mirror",
    });
  }, []);


  useEffect(() => {
    // Set the document title
    document.title = "Homepage || ConciseNext.com";

    // Create and set meta tags
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Empower Your Business with ConciseNext";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management";
    document.head.appendChild(metaKeywords);

    const metaRobots = document.createElement('meta');
    metaRobots.name = "robots";
    metaRobots.content = "index, follow";
    document.head.appendChild(metaRobots);

    // Set the canonical link
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = "canonical";
    canonicalLink.href = document.URL; // Adjust to the specific page
    document.head.appendChild(canonicalLink);

    // Open Graph tags
    const ogTitle = document.createElement('meta');
    ogTitle.setAttribute("property", "og:title");
    ogTitle.content = "ConciseNext: Your Cloud Application Hosting Solution"; // Title reflecting your service
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.setAttribute("property", "og:description");
    ogDescription.content = "Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs.";
    document.head.appendChild(ogDescription);

    const ogImage = document.createElement('meta');
    ogImage.setAttribute("property", "og:image");
    ogImage.content = "https://concisenext.com/static/media/logocolor.481479ea418411f3e450.png"; // Replace with actual image URL
    document.head.appendChild(ogImage);

    const ogUrl = document.createElement('meta');
    ogUrl.setAttribute("property", "og:url");
    ogUrl.content = document.URL; // Adjust to the specific page
    document.head.appendChild(ogUrl);


    // Cleanup function to remove meta tags on unmount
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
      document.head.removeChild(metaRobots);
      document.head.removeChild(canonicalLink);
      document.head.removeChild(ogTitle);
      document.head.removeChild(ogDescription);
      document.head.removeChild(ogImage);
      document.head.removeChild(ogUrl);
    };
  }, []); // Empty array means this runs on mount only

  const border = useMotionTemplate`1px solid ${color}`;
  const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;




  const CustomerQuerySection = () => {
    return (
      <div className="flex justify-center items-center min-h-screen py-12 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row bg-white shadow-2xl rounded-xl overflow-hidden max-w-7xl w-full transform transition-transform duration-500 ease-in-out">

          {/* Left Side - Form */}
          {/* <div className="lg:w-1/2 p-8 bg-gray-100">
            <h2 className="text-4xl font-extrabold text-[#35436a] mb-6">Drop Your Query</h2>
            <form className="space-y-6">
              <div className="relative">
                <label htmlFor="name" className="block text-lg font-semibold text-gray-800">
                  Enter Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                  placeholder="Your Name"
                />
              </div>

              <div className="relative">
                <label htmlFor="email" className="block text-lg font-semibold text-gray-800">
                  Enter Email ID
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                  placeholder="yourname@example.com"
                />
              </div>

              <div className="relative">
                <label htmlFor="phone" className="block text-lg font-semibold text-gray-800">
                  Phone Number
                </label>
                <div className="mt-2 flex">
                  <select
                    id="countryCode"
                    name="countryCode"
                    className="p-3 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-gray-100 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                  >
                    <option value="+1">+1 (USA)</option>
                    <option value="+44">+44 (UK)</option>
                    <option value="+91">+91 (India)</option>
                    <option value="+61">+61 (Australia)</option>
                    <option value="+81">+81 (Japan)</option>
                  </select>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="p-3 w-full border border-gray-300 rounded-r-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                    placeholder="Your Phone Number"
                  />
                </div>
              </div>

              <div className="relative">
                <label htmlFor="query" className="block text-lg font-semibold text-gray-800">
                  Drop Your Query
                </label>
                <textarea
                  id="query"
                  name="query"
                  className="mt-2 p-3 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-300 ease-in-out hover:shadow-lg"
                  rows="5"
                  placeholder="Describe your issue or question..."
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full py-3 bg-[#35436a] text-white font-bold rounded-md hover:bg-[#1a85cf] transition-colors duration-300 ease-in-out hover:shadow-lg"
              >
                Submit Query
              </button>
            </form>
          </div> */}
          <div className="lg:w-1/2">
            <QueryForm />
          </div>

          {/* Right Side - Content */}
          <div className="lg:w-1/2 p-8 bg-gradient-to-br to-gray-800 from-gray-600 text-white flex flex-col justify-center relative overflow-hidden">
            <div className="absolute inset-0 bg-gray-800 opacity-40 transform -skew-y-6"></div>
            <div className="relative z-10">
              <h3 className="text-3xl font-extrabold mb-4 animate-bounce">Need Assistance?</h3>
              <p className="text-lg mb-4">
                At <span className="font-semibold">ConciseNext</span>, we are here to help you with all your cloud hosting needs. Whether you have questions about our services, need support with your account, or want to learn more about our offerings, we're just a query away.
              </p>
              <p className="text-lg mb-4">
                Our dedicated team is available 24/7 to provide you with the best assistance. Fill out the form on the left, and one of our experts will get back to you as soon as possible.
              </p>
              <p className="text-lg">
                We value your time and strive to resolve your issues quickly and efficiently. Your satisfaction is our top priority.
              </p>
              <div className="mt-8">
                <h4 className="text-xl font-semibold mb-2">Why Choose ConciseNext?</h4>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Reliable and Secure Hosting</li>
                  <li>24x7 Customer Support</li>
                  <li>Industry-Leading Uptime</li>
                  <li>Advanced Security Features</li>
                  <li>Scalable Solutions Tailored to Your Needs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };




  const securityFeatures = [
    {
      icon: <FaLock className="text-4xl text-blue-600 animate-pulse" />,
      title: 'End-to-End Encryption',
      description: 'Your data is encrypted from the moment it leaves your device until it reaches its destination, ensuring absolute privacy.',
    },
    {
      icon: <FaUserShield className="text-4xl text-green-600 animate-spin-slow" />,
      title: 'Multi-Factor Authentication',
      description: 'Add an extra layer of protection by requiring multiple forms of verification, ensuring that your accounts are secure.',
    },
    {
      icon: <FaShieldAlt className="text-4xl text-red-600" />,
      title: 'Antivirus & Anti-Malware',
      description: 'Advanced solutions to protect your data from online threats, with continuous monitoring and updates.',
    },
    {
      icon: <FaShieldAlt className="text-4xl text-yellow-600 " />,
      title: 'Network Firewalls',
      description: 'Our firewalls provide a robust barrier between your data and potential cyber threats, ensuring secure data flow.',
    },
    {
      icon: <FaGlobe className="text-4xl text-purple-600 animate-pulse" />,
      title: '24x7 Network Monitoring',
      description: 'We monitor your network around the clock, detecting and mitigating any suspicious activity in real-time.',
    },
    {
      icon: <FaCloud className="text-4xl text-indigo-600 animate-spin-slow" />,
      title: 'Automated Data Backup',
      description: 'Your data is regularly and securely backed up, allowing for quick recovery in case of any unforeseen events.',
    },
    {
      icon: <FaSyncAlt className="text-4xl text-teal-600 animate-pulse" />,
      title: 'Regular Security Updates',
      description: 'Stay ahead of potential threats with our regular updates, ensuring your environment is always secure.',
    },
    {
      icon: <FaLockOpen className="text-4xl text-gray-600 animate-pulse" />,
      title: 'Secure Authentication Protocols',
      description: 'Utilize advanced authentication protocols such as OAuth and SAML to ensure secure and efficient user verification and access management.',
    },
    {
      icon: <FaDatabase className="text-4xl text-orange-600" />,
      title: 'Data Loss Prevention',
      description: 'Our Data Loss Prevention (DLP) systems help identify and protect sensitive information from accidental or malicious exposure and leaks.',
    },
  ];


  const SecurityFeatures = () => {
    return (
      <div className="py-12 bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-center text-gray-900 sm:text-4xl mb-4">
            Data Integrity & Security
          </h2>
          <p className="text-lg text-center text-gray-600 mb-12">
            At ConciseNext, safeguarding your data is our top priority. We employ state-of-the-art security measures to ensure that your information remains protected at all times. Our comprehensive security framework covers everything from encryption and firewalls to continuous monitoring and regular updates. You can trust us to keep your data safe, secure, and always available.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {securityFeatures.map((feature, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg transform hover:-translate-y-2 transition duration-500 hover:shadow-2xl"
              >
                <div className="flex items-center justify-center mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 text-center mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600 text-center">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="bg-bgtrans2 carousel relative">
        <div className="">
          <video
            className="absolute top-0 left-0 w-full h-[85vh] object-cover -z-10"
            autoPlay
            loop
            muted
          >
            <source src={BgVideo} type="video/mp4" />
            <source src={BgVideoWebM} type="video/webm" />
          </video>
        </div>

        <div className="   relative top-52 h-[400px] sm:h-[450px] md:h-[500px] lg:h-[400px] xl:h-[450px] 2xl:h-[500px]">
          <Carousel className="px-4 sm:px-6 md:px-8 lg:px-10">

            <div

              className="w-full md:w-4/5 lg:w-3/5 xl:w-1/2 2xl:w-2/5 p-4 md:p-6 flex flex-col items-start"
            >
              <div className=" pb-6 md:pb-8 lg:pb-9">
                <p className="text-xl sm:text-2xl md:text-3xl gap-4 text-white">
                  Seamless Cloud Hosting: Power, Flexibility, and Reliability at
                  Your Fingertips.
                </p>
                <p className="mt-4 mb-4 text-sm sm:text-base md:text-lg text-white">
                  Experience unparalleled performance with our cloud hosting
                  solutions. Enjoy scalable resources, high uptime, and unmatched
                  security, all tailored to your needs.
                </p>
              </div>

              <motion.button
                style={{
                  border,
                  boxShadow,
                }}
                whileHover={{ scale: 1.015 }}
                whileTap={{ scale: 0.985 }}
                className="group relative flex w-fit items-center gap-1.5 rounded-full px-4 py-3 bg-button text-gray-50 transition-colors hover:bg-gray-950/50"
              >
                <Link to={"/free-trial"}>START YOUR FREE TRIAL</Link>

                <FiArrowRight className="transition-transform group-hover:-rotate-45 group-active:-rotate-12" />
              </motion.button>
            </div>
            <div

              className="w-full md:w-4/5 lg:w-3/5 xl:w-1/2 2xl:w-2/5 p-4 md:p-6 flex flex-col items-start"
            >
              <div className=" pb-6 md:pb-8 lg:pb-9">
                <p className="text-xl sm:text-2xl md:text-3xl gap-4 text-white">
                  Elevate Your Cloud Experience: Speed, Scalability, and Security at Every Turn
                </p>
                <p className="mt-4 mb-4 text-sm sm:text-base md:text-lg text-white">
                  Unlock exceptional cloud performance with our tailored solutions. Benefit from flexible scalability, guaranteed uptime, and top-tier security—all customized for your business.
                </p>
              </div>

              <motion.button
                style={{
                  border,
                  boxShadow,
                }}
                whileHover={{ scale: 1.015 }}
                whileTap={{ scale: 0.985 }}
                className="group relative flex w-fit items-center gap-1.5 rounded-full px-4 py-3 bg-button text-gray-50 transition-colors hover:bg-gray-950/50"
              >
                <Link to={"/free-trial"}>START YOUR FREE TRIAL</Link>

                <FiArrowRight className="transition-transform group-hover:-rotate-45 group-active:-rotate-12" />
              </motion.button>

            </div>


          </Carousel>
        </div>
      </div>



      {/* QuickBooks Section */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <div className="row align-items-center">
            {/* Left Side - Image */}
            <div className="col-md-6 mb-6">
              <img src={quickbooksImage} alt="QuickBooks Software" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
            {/* Right Side - Description */}
            <div className="col-md-6 mb-6">
              <h2 className="text-3xl font-semibold mb-4">Streamline Your Accounting with QuickBooks</h2>
              <p className="text-lg text-gray-700 mb-4">
                QuickBooks is a powerful accounting software designed to simplify your business’s financial management. With intuitive features and easy integration, it helps you manage expenses, track income, and generate insightful reports with ease.
              </p>
              <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Automated financial tracking</li>
                <li>Customizable invoicing and payments</li>
                <li>Real-time financial reporting</li>
                <li>Secure cloud-based access</li>
              </ul>
              <Link to={"/quickbooks-hosting"} className="bg-[#35436a] text-white py-2 px-4 rounded-lg text-lg hover:bg-[#1a85cf] transition-colors">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>


      {/* Sage Section */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <div className="row align-items-center">
            {/* Left Side - Description */}
            <div className="col-md-6 mb-6">
              <h2 className="text-3xl font-semibold mb-4">Optimize Your Business Operations with Sage</h2>
              <p className="text-lg text-gray-700 mb-4">
                Sage offers comprehensive business management solutions that streamline your operations. From financial management to inventory control, Sage provides tools to enhance productivity and efficiency.
              </p>
              <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Integrated financial management</li>
                <li>Advanced inventory tracking</li>
                <li>Robust reporting and analytics</li>
                <li>Customizable to fit your business needs</li>
              </ul>
              <Link to={"/sage-hosting"} className="bg-[#35436a] text-white py-2 px-4 rounded-lg text-lg hover:bg-[#1a85cf] transition-colors">
                Learn More
              </Link>
            </div>
            {/* Right Side - Image */}
            <div className="col-md-6 mb-6">
              <img src={sageImage} alt="Sage Application" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
          </div>
        </div>
      </section>




      <section className="bg-gray-800 text-white py-16">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold mb-4">Empower Your Business with Our Cloud Solutions</h1>
          <p className="text-lg mb-8">
            Discover the future of cloud hosting with high performance, scalability, and security. Tailored solutions designed to fit your needs.
          </p>
          <Link to="/contact-us" className="bg-[#35436a] text-white py-3 px-20 rounded-lg text-lg hover:bg-[#1a85cf] transition-colors w-64">
            Contact Us
          </Link>
        </div>
      </section>


      <div><SecurityFeatures /></div>

      {/* Features Section */}
      <section className="py-9 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-semibold text-center mb-12">Why Choose <span style={{ color: "#35436a", fontSize: 35, fontWeight: "bold" }}>ConciseNext?</span></h2>
          <div className="row">
            {/* Feature 1 */}
            <div className="col-md-4 mb-6">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <h3 className="text-2xl font-bold mb-4">Scalable Solutions</h3>
                <p className="text-gray-700">
                  Easily scale your infrastructure to handle increased traffic and growth with our flexible cloud services.
                </p>
              </div>
            </div>
            {/* Feature 2 */}
            <div className="col-md-4 mb-6">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <h3 className="text-2xl font-bold mb-4">High Uptime</h3>
                <p className="text-gray-700">
                  Our cloud hosting ensures your applications remain accessible with a high uptime guarantee.
                </p>
              </div>
            </div>
            {/* Feature 3 */}
            <div className="col-md-4 mb-6">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <h3 className="text-2xl font-bold mb-4">Top-Notch Security</h3>
                <p className="text-gray-700">
                  Protect your data with advanced security measures and regular updates to keep threats at bay.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div><CustomerQuerySection /></div>

      {/* Testimonial Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-semibold text-center mb-12">What Our Clients Say</h2>
          <div className="flex flex-wrap -mx-4">
            {/* Testimonial 1 */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <p className="text-lg text-gray-700 mb-4">
                  "The cloud hosting service we received was exceptional. Our transition was smooth, and the support team was always there to assist us."
                </p>
                <h4 className="text-xl font-semibold mb-2">Jane Doe</h4>
                <p className="text-gray-500">CEO, Company X</p>
              </div>
            </div>
            {/* Testimonial 2 */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <p className="text-lg text-gray-700 mb-4">
                  "QuickBooks has been a game-changer for our accounting processes. It's intuitive and makes managing our finances much easier."
                </p>
                <h4 className="text-xl font-semibold mb-2">John Smith</h4>
                <p className="text-gray-500">Finance Director, Business Y</p>
              </div>
            </div>
            {/* Testimonial 3 */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <p className="text-lg text-gray-700 mb-4">
                  "Sage's solutions have streamlined our operations and provided valuable insights into our business performance."
                </p>
                <h4 className="text-xl font-semibold mb-2">Emily Johnson</h4>
                <p className="text-gray-500">Operations Manager, Firm Z</p>
              </div>
            </div>
          </div>
        </div>
      </section>



    </div >
  );
}
