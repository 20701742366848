import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTools, FaExchangeAlt, FaHandshake, FaCloud, FaComments, FaDatabase, FaServer, FaHeadset, FaCogs, FaUserCheck, FaDollarSign, FaUserClock, FaLayerGroup, FaShieldAlt, FaLaptop, FaReact, FaExpand, FaPlaystation, } from 'react-icons/fa';





export default function Sage300Hosting() {

    const SageHero = () => {
        const { scrollY } = useScroll();
        const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
        const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
        const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
        const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

        return (
            <div className="relative bg-[url('https://media.licdn.com/dms/image/D4D12AQFMv8V-eQ8nOQ/article-cover_image-shrink_600_2000/0/1679145567850?e=2147483647&v=beta&t=xEJ8LEH_ubyYe_IcbgooMReM5tNP2l2UsomiDa_bLeE')] bg-cover bg-center bg-no-repeat  h-[500px] md:h-[600px] overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Sage 300 ERP Hosting With <span className='text-blue-600 font-bold'>ConciseNext</span>
                    </motion.h1>
                    <motion.p
                        className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        Unlock the full potential of Sage 300 ERP with ConciseNext's cutting-edge cloud hosting solutions. Seamlessly manage your business operations from anywhere, at any time, with unmatched security, scalability, and dedicated expert support.
                    </motion.p>
                    <motion.a
                        href="/free-trial"
                        className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
                    >
                        Learn More
                    </motion.a>
                </div>

                {/* Parallax Image */}
                <motion.img
                    src="https://www.eliteintegrations.com/wp-content/uploads/2022/09/banner-till.png"
                    alt="Sage 300"
                    className="absolute bottom-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 right-0 transform translate-y-8 md:translate-y-16 sm:translate-y-0"
                    style={{ y: yImage }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                />
            </div>
        );
    };



    const SageIntroSection = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView && window.innerWidth > 768) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto p-8 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Sage 300 ERP Hosting Services - ConciseNext
                </h2>

                <div className="flex flex-col md:flex-row gap-8 justify-center items-center">
                    <img
                        src="https://concisenext.com/public_image/Sage-300-Tips-Website-1600x900.webp"
                        alt="Sage 50"
                        className="rounded-lg shadow-lg w-[400px] h-[auto]"
                    />
                    <p className="text-lg text-gray-700 leading-relaxed text-center md:text-left">
                        Streamline your business operations with ConciseNext’s Sage 300 ERP Hosting Services, offering secure, scalable, and remote access to your Sage 300 ERP software. Manage your accounting, financials, distribution, and inventory with ease while enjoying the flexibility of cloud-based access.
                    </p>
                </div>

                <h3 className="text-3xl pt-8 font-bold text-gray-800 text-center md:text-left">
                    Why Choose ConciseNext for Sage 300 ERP Hosting?
                </h3>

                <ul className="space-y-8">
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaLaptop className="w-8 h-8 text-blue-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Remote Access from Anywhere</h4>
                            <p className="text-lg text-gray-700">
                                Access your Sage 300 ERP software from any location on any device with our robust cloud hosting platform. Work on your business management tools whether you're in the office or working remotely, ensuring continuous productivity.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaDollarSign className="w-8 h-8 text-green-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cost-Effective Solution</h4>
                            <p className="text-lg text-gray-700">
                                Eliminate the need for expensive hardware and IT maintenance with our Sage 300 hosting. You only pay for the resources you use, with the flexibility to scale your cloud environment as your business grows.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaReact className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Seamless Multi-User Collaboration</h4>
                            <p className="text-lg text-gray-700">
                                Host your Sage 300 ERP in the cloud and allow multiple users to work in real time. This improves collaboration across teams and departments, ensuring accurate and up-to-date information is always available.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaShieldAlt className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Enhanced Security</h4>
                            <p className="text-lg text-gray-700">
                                Our cloud hosting services provide enterprise-level security, including data encryption, firewalls, and daily backups, ensuring your sensitive business data is protected 24/7.

                            </p>
                        </div>
                    </li>

                </ul>
            </motion.div>
        );
    };

    const SageBenefits = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto  p-8 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Key Benefits of Hosting Sage 300 ERP with ConciseNext
                </h2>

                {/* License Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
                    {[
                        {
                            title: '99.9% Uptime Guarantee',
                            description:
                                'Enjoy reliable and uninterrupted access to your Sage 300 ERP system with our high-availability cloud infrastructure, ensuring your business runs smoothly without costly downtime.',
                            imageUrl:
                                'https://concisenext.com/public_image/information-technology-banner-design-10-copy.webp',
                            link: '#',
                        },
                        {
                            title: 'Fully Managed Cloud Environment',
                            description:
                                'We handle all technical aspects, including server management, updates, security patches, and backups. This allows you to focus on running your business while we take care of the IT.',
                            imageUrl:
                                'https://concisenext.com/public_image/Data-Backup.webp',
                            link: '#',
                        },

                        {
                            title: '24/7 Technical Support',
                            description:
                                'Our team of experts is available around the clock to provide assistance with any hosting issues or Sage 300 ERP-related questions, ensuring minimal disruptions to your business operations.',
                            imageUrl:
                                'https://concisenext.com/public_image/images.webp',
                            link: '#',
                        },
                        {
                            title: 'Fast and Secure Migration',
                            description:
                                'Transitioning your Sage 300 ERP to the cloud is a smooth and seamless process. Our experienced team ensures a quick migration with minimal downtime, guaranteeing all your data is transferred safely.',
                            imageUrl:
                                'https://concisenext.com/public_image/technical-support-1.webp',
                            link: '#',
                        },
                    ].map((license, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-start space-y-4 transition-transform duration-300"
                        >
                            <img
                                src={license.imageUrl}
                                alt={license.title}
                                className="rounded-lg shadow-lg w-full h-48 object-cover"
                            />
                            <h4 className="text-xl font-semibold text-gray-800">
                                {license.title}
                            </h4>
                            <p className="text-gray-600 pb-10">{license.description}</p>

                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };



    const GetStartedSection = () => {
        return (
            <section className="relative bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 py-16 text-white">
                <div className="absolute inset-0 bg-black opacity-30"></div>
                <div className="relative max-w-7xl mx-auto px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-4xl font-bold mb-4">
                            Planning to Get Started with Hosted Sage 300 ERP?
                        </h2>
                        <p className="text-lg mb-8">
                            Explore the advantages of our cloud-hosted Sage 300 ERP solutions. Optimize your business processes and boost productivity with our premier hosting services, designed to deliver seamless performance and robust scalability.
                        </p>
                        <a
                            href="/free-trial"
                            className="bg-button text-white py-3 px-6 rounded-lg font-semibold text-lg transition-transform transform hover:scale-105 hover:bg-red-600"
                        >
                            Get Free Trial
                        </a>
                    </div>
                </div>
            </section>
        );
    };



    const SageHostingWorks = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-6xl mx-auto p-8 pt-12 space-y-12 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    How Our Sage 300 ERP Hosting Works
                </h2>

                <div className="relative">
                    <div className="absolute left-1/2 top-0 transform -translate-x-1/2 h-full border-l-2 border-dashed border-gray-300" />
                    <div className="space-y-8">
                        {[
                            {
                                step: 'Consultation',
                                description: 'Get in touch with us to discuss your Sage 300 ERP needs. We’ll tailor a cloud hosting solution that best fits your business requirements.',
                                icon: <FaComments className="w-10 h-10 text-blue-500" />,
                            },
                            {
                                step: 'Migration',
                                description: 'We handle the migration of your Sage 300 ERP system and data to our secure cloud servers, ensuring no data is lost and there is minimal disruption to your business processes.',
                                icon: <FaExchangeAlt className="w-10 h-10 text-green-500" />,
                            },
                            {
                                step: 'Cloud Access',
                                description: 'Once your Sage 300 ERP is hosted in the cloud, you’ll enjoy secure, remote access to your software from any device with internet access, ensuring your team can work efficiently from anywhere.',
                                icon: <FaCloud className="w-10 h-10 text-purple-500" />,
                            },
                            {
                                step: 'Ongoing Management',
                                description: 'Our team provides continuous monitoring, regular backups, software updates, and proactive support to ensure your Sage 300 ERP system remains secure and functional at all times.',
                                icon: <FaHeadset className="w-10 h-10 text-red-500" />,
                            },
                        ].map((step, index) => (
                            <div key={index} className="relative bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center space-y-4">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 flex items-center justify-center bg-white rounded-full border-4 border-gray-200">
                                    {step.icon}
                                </div>
                                <div className="mt-12">
                                    <h3 className="text-2xl font-semibold text-gray-800">{step.step}</h3>
                                    <p className="text-lg text-gray-600 mt-2">{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        );
    };


    const WhyChooseConciseNext = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const cardVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.6,
                    ease: "easeOut",
                    when: "beforeChildren",
                    staggerChildren: 0.3,
                },
            },
        };

        const iconVariants = {
            hidden: { scale: 0 },
            visible: {
                scale: 1,
                transition: {
                    duration: 0.5,
                    ease: "backOut",
                },
            },
        };

        return (
            <div className="bg-gray-50 py-9">
                <div className="max-w-7xl mx-auto px-6 sm:px-8">
                    <motion.h2
                        className="text-4xl font-bold text-center text-gray-800 mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Why Businesses Choose ConciseNext for Sage 300 ERP Hosting
                    </motion.h2>

                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={cardVariants}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8"
                    >
                        {[
                            {
                                title: "Trusted Expertise",
                                description:
                                    "We specialize in hosting accounting and ERP software like Sage 300, ensuring you receive a hosting solution tailored to the needs of your business.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-blue-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaExpand size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Custom Hosting Plans",
                                description:
                                    "We offer flexible hosting plans that are customized to your business size and scale. Whether you’re a small business or a large enterprise, we provide the right level of resources for your Sage 300 ERP environment.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-green-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaPlaystation size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Industry-Leading Security",
                                description:
                                    "Our cloud hosting environment is built with advanced security features, including data encryption, multi-factor authentication, and SOC-certified data centers, ensuring your business data is fully protected.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaShieldAlt size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Simplified IT Management",
                                description:
                                    "We handle all the complex IT tasks, so you don’t have to. With ConciseNext, you get fully managed hosting services that include maintenance, disaster recovery, and security monitoring.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaLaptop size={48} />
                                    </motion.div>
                                ),
                            },
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-lg shadow-lg text-start flex flex-col items-center"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    };

    const Sage50HostingPlans = () => {
        // Variants for scroll animation
        const variants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
        };

        return (
            <div className="max-w-7xl mx-auto p-8">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
                    Sage 300 ERP Hosting Plans We Offer
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Single-User Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-start transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Single-User Sage 300 ERP Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            Perfect for small businesses or individuals who need secure and reliable cloud access to their Sage 300 ERP software.
                        </p>
                        <a
                            href="/free-trial"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Learn More
                        </a>
                    </motion.div>
                    {/* Multi-User Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-start transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Multi-User Sage 300 ERP Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            Designed for growing businesses, this plan supports multiple users, allowing seamless collaboration across departments in real time.
                        </p>
                        <a
                            href="/free-trial"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Learn More
                        </a>
                    </motion.div>
                    {/* Custom Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-start transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Enterprise-Level Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            For larger organizations, we offer enterprise-level hosting that provides advanced performance, security, and scalability to support complex business operations.
                        </p>
                        <a
                            href="/free-trial"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Learn More
                        </a>
                    </motion.div>
                </div>
            </div>
        );
    };


    const ContactUs = () => {
        return (
            <section className="relative bg-gray-100 py-7 px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="absolute inset-0">
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 translate-y-1/4 -z-10" width="404" height="404" fill="none" viewBox="0 0 404 404">
                        <defs>
                            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-indigo-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#pattern1)" />
                    </svg>
                </div>
                <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <motion.h2
                        className="text-3xl font-extrabold text-gray-900 mb-6"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        Start Your Sage 300 ERP Hosting Journey with ConciseNext
                    </motion.h2>
                    <motion.p
                        className="text-lg text-gray-600 mb-8"
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut', delay: 0.2 }}
                    >
                        Upgrade your business management capabilities with our reliable Sage 300 ERP cloud hosting. Enhance productivity, improve security, and reduce operational costs by moving your ERP system to the cloud. Contact our team today to get started with a hosting plan tailored to your needs.
                    </motion.p>
                    <div className="flex flex-col items-center">
                        <motion.div
                            className="text-gray-800 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
                        >
                            <p className="text-lg font-semibold">📞 Call us: +1 (800) 123-4567</p>
                            <p className="text-lg font-semibold">📧 Email us: <a href="mailto:sales@ConciseNext.com" className="text-indigo-600 hover:underline">sales@ConciseNext.com</a></p>
                        </motion.div>
                        <motion.p
                            className="text-gray-500"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
                        >
                            ConciseNext – Your trusted partner for secure and scalable Sage 300 ERP hosting solutions.
                        </motion.p>
                    </div>
                </div>
            </section>
        );
    };


    useEffect(() => {
        // Set the document title
        document.title = "Sage 300 Hosting || ConciseNext.com";

        // Create and set meta tags
        const metaDescription = document.createElement('meta');
        metaDescription.name = "description";
        metaDescription.content = "Unlock the full potential of Sage 300 ERP with ConciseNext's cutting-edge cloud hosting solutions";
        document.head.appendChild(metaDescription);

        const metaKeywords = document.createElement('meta');
        metaKeywords.name = "keywords";
        metaKeywords.content = "concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management";
        document.head.appendChild(metaKeywords);

        const metaRobots = document.createElement('meta');
        metaRobots.name = "robots";
        metaRobots.content = "index, follow";
        document.head.appendChild(metaRobots);

        // Set the canonical link
        const canonicalLink = document.createElement('link');
        canonicalLink.rel = "canonical";
        canonicalLink.href = document.URL; // Adjust to the specific page
        document.head.appendChild(canonicalLink);

        // Open Graph tags
        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute("property", "og:title");
        ogTitle.content = "ConciseNext: Your Cloud Application Hosting Solution"; // Title reflecting your service
        document.head.appendChild(ogTitle);

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute("property", "og:description");
        ogDescription.content = "Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs.";
        document.head.appendChild(ogDescription);

        const ogImage = document.createElement('meta');
        ogImage.setAttribute("property", "og:image");
        ogImage.content = "https://concisenext.com/static/media/logocolor.481479ea418411f3e450.png"; // Replace with actual image URL
        document.head.appendChild(ogImage);

        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute("property", "og:url");
        ogUrl.content = document.URL; // Adjust to the specific page
        document.head.appendChild(ogUrl);


        // Cleanup function to remove meta tags on unmount
        return () => {
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);
            document.head.removeChild(canonicalLink);
            document.head.removeChild(ogTitle);
            document.head.removeChild(ogDescription);
            document.head.removeChild(ogImage);
            document.head.removeChild(ogUrl);
        };
    }, [])

    return (
        <div>
            <SageHero />
            <SageIntroSection />
            <SageBenefits />
            <SageHostingWorks />
            <WhyChooseConciseNext />
            <GetStartedSection />
            <Sage50HostingPlans />
            <ContactUs />


        </div>
    )

}