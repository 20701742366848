import React, { useEffect, useRef } from 'react';
import { ShieldCheckIcon, GlobeAltIcon, ClockIcon, ChartBarIcon, SupportIcon, CloudIcon } from '@heroicons/react/solid';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import dataSec from "../assets/images/datasecurity.webp";
import uptime from "../assets/images/uptime.webp";
import scalable from "../assets/images/scalable.webp";
import accessibility from "../assets/images/Accessibility.webp"
import Hossec from "../assets/images/hostingsec.webp"
import qbpre from "../assets/images/quickbookpre.webp"
import multiUsr from "../assets/images/multiUsr.webp"
import accdata from "../assets/images/products.svg"
import dataBckp from "../assets/images/dataBackup.webp"
import qpro from "../assets/images/quickbookspro.webp"
import relper from "../assets/images/relper.webp"
import techsuport from "../assets/images/techsup.webp"
import teamwrk from "../assets/images/teamwrk.webp"
import qbpro from "../assets/images/qb/pro.webp"
import { FaUser, FaUsers, FaHdd, FaMemory, FaAppStore } from 'react-icons/fa';

const features = [
  {
    id: 1,
    name: 'Anywhere, Anytime Access',
    icon: ShieldCheckIcon,
    description: 'With ConciseNext’s QuickBooks Pro hosting, your accounting data is accessible from any device, at any location. Whether you’re in the office, at home, or traveling, you can effortlessly stay connected to your financial data.',
    image: accdata,
  },
  {
    id: 2,
    name: 'Advanced Security Measures',
    icon: GlobeAltIcon,
    description: 'Protect your sensitive financial information with our robust security infrastructure. ConciseNext utilizes the latest in data encryption, secure data centers, and continuous monitoring to ensure your data remains safe and secure at all times.',
    image: dataSec,
  },
  {
    id: 3,
    name: 'Reliable Performance and Uptime',
    icon: ClockIcon,
    description: 'Never worry about downtime affecting your business. ConciseNext guarantees 99.9% uptime, so your QuickBooks Pro software is always available when you need it. Our reliable servers and redundant systems are designed to keep your operations running smoothly.',
    image: "https://concisenext.com/public_image/cloud-computing.webp",
  },
  {
    id: 4,
    name: 'Seamless Collaboration',
    icon: ChartBarIcon,
    description: 'Enhance teamwork with ConciseNext’s cloud-based QuickBooks Pro hosting. Multiple users can access and work on the same files simultaneously, ensuring that your team stays on the same page, no matter where they are.',
    image: teamwrk,
  },
  // {
  //   id: 9,
  //   name: '24/7 Dedicated Support',
  //   icon: SupportIcon,
  //   description: 'Our experienced support team is available around the clock to assist with any technical issues or questions. With ConciseNext, you’ll have access to knowledgeable Quickbooks pro experts whenever you need them, ensuring your operations run smoothly.',
  //   image: support,
  // },
  // {
  //   id: 8,
  //   name: 'Scalable and Cost-Effective Hosting',
  //   icon: CloudIcon,
  //   description: 'As your business grows, so do your hosting needs. ConciseNext offers scalable hosting solutions that can expand alongside your business, all while remaining cost-effective. Save on IT costs by eliminating the need for on-premise servers and maintenance.',
  //   image: qbHosting,
  // },
];





const BenefitsSection = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });

  return (
    <div className="bg-white py-6">
      <div className="container mx-auto px-6">
        <h2 className={`text-3xl font-bold text-center  ${inView1 ? 'animate-fadeIn' : ''}`}>
          Key Benefits of QuickBooks Hosting with ConciseNext
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          <div
            ref={ref1}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView1 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Expert Support Available 24/7</h3>
            <p className="mb-4 card-info">
              Our team of QuickBooks Pro experts is on standby to assist you at any time. ConciseNext provides 24/7 customer support, ensuring that any issues are resolved promptly, so your business operations remain uninterrupted.
            </p>
            <img
              src={techsuport}
              alt="Accessibility"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref2}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView2 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Cost-Effective Hosting Solutions</h3>
            <p className="mb-4 card-info">
              Reduce your IT expenses with ConciseNext’s affordable QuickBooks Pro hosting. By hosting your QuickBooks Pro in the cloud, you eliminate the need for costly in-house servers and IT maintenance, allowing you to focus on what matters most—growing your business.
            </p>
            <img
              src={dataBckp}
              alt="Backup and Recovery"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref3}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView3 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Multi-User Collaboration</h3>
            <p className="mb-4 card-info">
              Collaborate with your team in real-time. With QuickBooks hosting by ConciseNext, multiple users can access and work on the same files simultaneously.
            </p>
            <img
              src={multiUsr}
              alt="Collaboration"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HostingSection = () => {
  return (
    <div className="bg-gray-100 py-9 px-4 md:px-6">
      {/* Container */}
      <div className="container mx-auto max-w-7xl">
        {/* Header Section */}
        <div className="text-center mb-3">
          <h1 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-2 md:mb-4">
            QuickBooks Pro Hosting by ConciseNext
          </h1>
          <p className="text-base md:text-lg text-gray-600">
            Secure, Reliable, and Scalable Cloud Hosting Solutions
          </p>
        </div>
        {/* Content Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 items-center">
          {/* Text Content */}
          <div className="space-y-4 md:space-y-6">
            <p className="text-base md:text-xl text-gray-700 leading-relaxed">
              ConciseNext offers premier QuickBooks Pro hosting services designed to meet the needs of accountants, CPAs, and small to medium-sized businesses. Our hosting solutions bring the full power of QuickBooks Pro to the cloud, enabling you to manage your finances with ease and flexibility.
            </p>
            <div className="flex flex-wrap space-y-4 md:space-y-0 md:space-x-4">
              <div className="flex items-center space-x-2 w-full md:w-auto">
                <ShieldCheckIcon className="h-6 md:h-8 w-6 md:w-8 text-blue-600" />
                <span className="font-semibold text-gray-800 text-sm md:text-base">Secure</span>
              </div>
              <div className="flex items-center space-x-2 w-full md:w-auto">
                <GlobeAltIcon className="h-6 md:h-8 w-6 md:w-8 text-green-600" />
                <span className="font-semibold text-gray-800 text-sm md:text-base">Reliable</span>
              </div>
              <div className="flex items-center space-x-2 w-full md:w-auto">
                <CloudIcon className="h-6 md:h-8 w-6 md:w-8 text-purple-600" />
                <span className="font-semibold text-gray-800 text-sm md:text-base">Scalable</span>
              </div>
            </div>
          </div>
          {/* Image */}
          <div className="relative self-center">
            <img
              src={qbpro}
              alt="Cloud Hosting"
              className="rounded-lg shadow-lg transform hover:scale-105 transition duration-500 h-60 md:h-80 w-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuickBooksProHostingIntro = () => {
  return (
    <section className="bg-gradient-to-r from-blue-800 via-blue-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Quickbooks pro Hosting Pricing & Plans
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          At <span className="font-bold">ConciseNext</span>, we offer flexible hosting plans designed to meet the unique needs of businesses of all sizes. Whether you're an individual user or part of a growing team, our plans provide the performance, security, and scalability you need to keep your accounting operations running smoothly.
        </motion.p>
      </div>
    </section>
  );
};

// const StandardHostingPlan = () => {
//   const standardPlans = [
//     {
//       title: '1-User Plan',
//       price: '$33',
//       description: 'Per Month',
//       storage: '5GB Per User',
//       color: 'bg-purple-100',
//     },
//     {
//       title: 'Per Additional User',
//       price: '$30',
//       description: 'Per Month',
//       storage: '5GB Per User',
//       color: 'bg-pink-100',
//     }
//   ];

//   return (
//     <section className="py-16 bg-gray-100">
//       <div className="container mx-auto px-4">
//         <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Standard Hosting Plans</h2>

//         <div className="flex flex-wrap justify-center gap-6">
//           {standardPlans.map(plan => (
//             <motion.div
//               key={plan.title}
//               className={`${plan.color} p-6 rounded-3xl shadow-lg border border-gray-200 w-full sm:w-[48%] md:w-[30%] lg:w-[18%] h-[250px] flex flex-col justify-between`}
//               whileHover={{ scale: 1.05 }}
//               whileTap={{ scale: 0.98 }}
//               transition={{ duration: 0.3 }}
//             >
//               <div className="text-center">
//                 <h3 className="text-2xl font-semibold mb-2 text-gray-800">{plan.title}</h3>
//                 <div className="text-3xl font-bold text-gray-800 mb-1">{plan.price}</div>
//                 <div className="text-lg text-gray-600 mb-4">{plan.description}</div>
//               </div>
//               <div className="flex items-center justify-center text-gray-600">
//                 <FaHdd className="text-blue-600 mr-2" />
//                 <span>{plan.storage}</span>
//               </div>
//             </motion.div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };


const Hero = () => {
  const { scrollY } = useScroll();
  const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
  const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
  const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
  const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

  return (
    <div className="relative h-[500px] md:h-[600px]  bg-[url('https://cdn.pixabay.com/photo/2023/08/14/15/42/milkyway-8190232_1280.jpg')] bg-cover bg-center bg-no-repeat overflow-hidden">
      {/* Background Circles Effect */}
      <div className="absolute flex items-center justify-center">
        <motion.div
          className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
          style={{ scale: scale1 }}
        />
        <motion.div
          className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
          style={{ scale: scale2 }}
        />
        <motion.div
          className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
          style={{ scale: scale3 }}
        />
      </div>

      {/* Hero Content */}
      <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
        <motion.h1
          className="text-3xl sm:text-4xl md:text-3xl font-bold mb-2 md:mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Transform Your Accounting Workflow with ConciseNext’s Elite QuickBooks Pro Hosting
        </motion.h1>
        <motion.p
          className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
        >
          Discover Unrivaled Security, Reliability, and Flexibility Tailored for Accounting Professionals
        </motion.p>
        <motion.a
          href="/free-trial"
          className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
        >
          Learn More
        </motion.a>
      </div>



    </div>
  );
};

const DifferenceSection = () => {
  return (
    <div className="relative py-20 px-6">
      {/* Background Effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-800 via-blue-500 to-purple-300 opacity-1 "></div>
      {/* Content */}
      <div className="relative container mx-auto max-w-5xl text-center text-white">
        <h2 className="text-4xl font-extrabold mb-6">Unlock the Full Potential of Quickbooks pro with ConciseNext</h2>
        <p className="text-lg leading-relaxed mb-8">
          Choosing ConciseNext for your Quickbooks Pro hosting means partnering with a provider that understands the importance of reliable and secure financial management. With our cloud-hosted solutions, you can focus on growing your business while we handle the technical aspects.
        </p>
        <button className="bg-white text-button font-semibold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition transform hover:scale-105">
          Learn More
        </button>
      </div>
    </div>
  );
};

const SmoothScrollFeature = ({ feature, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.7 });

  useEffect(() => {
    if (inView && window.innerWidth > 768) {  // Check if screen is larger than 768px
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={window.innerWidth > 768 ? controls : 'visible'}  // Disable animation on small screens
      variants={{
        hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
        visible: { opacity: 1, x: 0 },
      }}
      transition={{ duration: 0.7, ease: 'easeOut' }}
      className="grid md:grid-cols-2 gap-8 xl:gap-20 items-center py-8"
    >
      {/* Image */}
      <div className={`${index % 2 === 0 ? 'order-last md:order-first' : ''}`}>
        <img
          src={feature.image}
          alt={feature.name}
          className="rounded-lg shadow-lg transform hover:scale-105 h-[auto] w-[400px] xl:h-[auto] xl:w-[600px] transition duration-500"
        />
      </div>
      {/* Text */}
      <div>
        <h3 className="text-2xl font-bold text-gray-800 mb-4">{feature.name}</h3>
        <p className="text-lg text-gray-700 leading-relaxed">{feature.description}</p>
      </div>
    </motion.div>
  );
};

const QuickBooksPro = () => {


  useEffect(() => {
    // Set the document title
    document.title = "Quickbooks Pro Hosting || ConciseNext.com";

    // Create and set meta tags
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Transform Your Accounting Workflow with ConciseNext’s Elite QuickBooks Pro Hosting";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management";
    document.head.appendChild(metaKeywords);

    const metaRobots = document.createElement('meta');
    metaRobots.name = "robots";
    metaRobots.content = "index, follow";
    document.head.appendChild(metaRobots);

    // Set the canonical link
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = "canonical";
    canonicalLink.href = document.URL; // Adjust to the specific page
    document.head.appendChild(canonicalLink);

    // Open Graph tags
    const ogTitle = document.createElement('meta');
    ogTitle.setAttribute("property", "og:title");
    ogTitle.content = "ConciseNext: Your Cloud Application Hosting Solution"; // Title reflecting your service
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.setAttribute("property", "og:description");
    ogDescription.content = "Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs.";
    document.head.appendChild(ogDescription);

    const ogImage = document.createElement('meta');
    ogImage.setAttribute("property", "og:image");
    ogImage.content = "https://concisenext.com/static/media/logocolor.481479ea418411f3e450.png"; // Replace with actual image URL
    document.head.appendChild(ogImage);

    const ogUrl = document.createElement('meta');
    ogUrl.setAttribute("property", "og:url");
    ogUrl.content = document.URL; // Adjust to the specific page
    document.head.appendChild(ogUrl);


    // Cleanup function to remove meta tags on unmount
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
      document.head.removeChild(metaRobots);
      document.head.removeChild(canonicalLink);
      document.head.removeChild(ogTitle);
      document.head.removeChild(ogDescription);
      document.head.removeChild(ogImage);
      document.head.removeChild(ogUrl);
    };
  }, []); // Empty array means this runs on mount only
  return (
    <div>
      <Hero />
      <HostingSection />
      <div className="bg-white">
        <div className="container mx-auto max-w-7xl">
          {features.map((feature, index) => (
            <SmoothScrollFeature key={feature.id} feature={feature} index={index} />
          ))}
        </div>
      </div>

      <DifferenceSection />
      <BenefitsSection />

    </div>
  );
};

export default QuickBooksPro;
