import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTools, FaExchangeAlt, FaHandshake, FaCloud, FaComments, FaDatabase, FaServer, FaHeadset, FaCogs, FaUserCheck, FaDollarSign, FaUserClock, FaLayerGroup, FaShieldAlt, FaLaptop, FaUserFriends, FaLock, } from 'react-icons/fa';



export default function LacerteHosting() {

    const TaxHero = () => {
        const { scrollY } = useScroll();
        const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
        const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
        const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
        const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

        return (
            <div className="relative h-[500px] md:h-[600px] bg-[url('https://blogimage.vantagecircle.com/content/images/2020/08/technology-in-the-workplace.png')] bg-center bg-no-repeat bg-cover overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-gray-100 text-center px-4 md:px-6">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Lacerte Hosting  with <span className='text-blue-600 font-bold'>ConciseNext</span>
                    </motion.h1>
                    <motion.p
                        className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        Enhance your tax preparation with ConciseNext’s Lacerte Hosting, providing secure, remote access for seamless collaboration. Our scalable solutions streamline tax filing for professionals and teams alike.
                    </motion.p>
                    <motion.a
                        href="/free-trial"
                        className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
                    >
                        Learn More
                    </motion.a>
                </div>

                {/* Parallax Image */}
                {/* <motion.img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf0J11mGCr4qq2ijT_M-BkcRpwBC9Hw_JKGP3_gcRakZAv1oSAWrooX9aS9uVTUX9gFF4&usqp=CAU"
                    alt="QuickBooks"
                    className="absolute bottom-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 right-0 transform translate-y-8 md:translate-y-16 sm:translate-y-0"
                    style={{ y: yImage }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                /> */}
            </div>
        );
    };



    const TaxIntroSection = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView && window.innerWidth > 768) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Lacerte Hosting - ConciseNext
                </h2>

                <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
                    <img
                        src="https://media.licdn.com/dms/image/D4D12AQGIJyoQxVHAvw/article-cover_image-shrink_720_1280/0/1684763753506?e=2147483647&v=beta&t=WSjP6GphesKa1Q0RkQpVLA-7pm0Rz-1my76_ymt-1kI"
                        alt="QuickBooks License"
                        className="rounded-lg shadow-lg w-[400px] h-[auto]"
                    />
                    <p className="text-lg text-gray-700 leading-relaxed text-center md:text-left">
                        Take your tax preparation to the next level with ConciseNext’s Lacerte Hosting services. Our cloud-based hosting solution allows tax professionals and accounting firms to work efficiently by providing remote access to Lacerte Tax Software from any device, at any time. Enhance your productivity, ensure data security, and reduce IT costs with our fully managed Lacerte hosting services.
                    </p>
                </div>

                <h3 className="text-3xl pt-8 font-bold text-gray-800 text-center md:text-left">
                    Why Choose ConciseNext for Lacerte Hosting?
                </h3>

                <ul className="space-y-8">
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaLaptop className="w-8 h-8 text-blue-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Access from Anywhere</h4>
                            <p className="text-lg text-gray-700">
                                With ConciseNext’s cloud hosting, you can access Lacerte from any internet-connected device, whether you’re at the office, home, or traveling. This flexibility allows you to work seamlessly, regardless of location.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaUserFriends className="w-8 h-8 text-green-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Multi-User Capability</h4>
                            <p className="text-lg text-gray-700">
                                Allow multiple team members to work on Lacerte files at the same time. Our cloud hosting environment promotes efficient collaboration, speeding up tax preparation and filing processes.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaShieldAlt className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Data Security and Compliance</h4>
                            <p className="text-lg text-gray-700">
                                Protecting sensitive client data is a top priority. Our SOC-certified data centers offer multi-layered security, including encryption, firewalls, and regular security audits to ensure compliance with industry standards.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaDollarSign className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cost-Efficient Solution</h4>
                            <p className="text-lg text-gray-700">
                                Save on hardware, maintenance, and infrastructure costs with ConciseNext. Our cloud-based hosting offers flexible, scalable solutions that are tailored to meet your firm’s needs without the burden of managing on-site servers.
                            </p>
                        </div>
                    </li>

                </ul>
            </motion.div>
        );
    };

    const TaxBenefits = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto pt-8 p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Key Benefits of Lacerte Hosting with ConciseNext
                </h2>

                {/* License Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
                    {[
                        {
                            title: '99.9% Uptime ',
                            description:
                                'With our reliable cloud infrastructure, your Lacerte Tax Software will always be available when you need it. We minimize downtime, so you can stay productive during tax season.',
                            imageUrl:
                                'https://concisenext.com/public_image/960x0.webp',
                            link: '/quickbooks-pro-hosting',
                        },
                        {
                            title: 'Fully Managed Cloud Hosting',
                            description:
                                'We handle everything from server maintenance, software updates, and data backups to security patches. This ensures optimal performance and gives you peace of mind to focus on your tax work.',
                            imageUrl:
                                'https://concisenext.com/public_image/hr-learn20-gpstrategies-thumb.webp',
                            link: '#',
                        },

                        {
                            title: '24/7 Expert Support',
                            description:
                                'Whether it’s a technical issue or a question about your hosted environment, our dedicated support team is available 24/7 to provide assistance, ensuring that your Lacerte experience is seamless.',
                            imageUrl:
                                'https://concisenext.com/public_image/best-online-tech-support-services.webp',
                            link: '#',
                        },
                        {
                            title: 'Fast, Secure Migration',
                            description:
                                'Transitioning your Lacerte Tax Software to the cloud with ConciseNext is quick and easy. Our migration team ensures a smooth process with minimal disruption to your workflow.',
                            imageUrl:
                                'https://concisenext.com/public_image/technology-driven-workplace-transformation-and-how-to-go-about-it.webp',
                            link: '#',
                        },
                    ].map((license, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            className="bg-white rounded-lg shadow-lg p-5 flex flex-col items-center text-start space-y-4 transition-transform duration-300"
                        >
                            <img
                                src={license.imageUrl}
                                alt={license.title}
                                className="rounded-lg shadow-lg w-full h-48 object-cover"
                            />
                            <h4 className="text-xl font-semibold text-gray-800">
                                {license.title}
                            </h4>
                            <p className="text-gray-600 pb-10">{license.description}</p>

                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };


    const TaxHostingSolutions = () => {
        const controls = useAnimation();
        const { ref, inView } = useInView({ threshold: 0.2 });

        React.useEffect(() => {
            if (inView) {
                controls.start('visible');
            } else {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const fadeInVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
        };
        const isLargeScreen = window.innerWidth >= 768;

        return (
            <div className="bg-gray-100 text-gray-800 py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold text-center mb-12">Lacerte Hosting Plans</h2>

                    <div ref={ref} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-blue-500"
                            initial="hidden"
                            animate={isLargeScreen ? controls : 'visible'}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaCloud className="text-blue-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Single-User Drake Tax Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Perfect for individual tax professionals who want to access Lacerte Tax Software remotely with the security and flexibility of the cloud.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 hover:bg-red-700 hover:text-white transition-colors rounded-md">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-green-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaServer className="text-green-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Multi-User Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Ideal for small to mid-sized accounting firms, enabling multiple users to collaborate on tax returns simultaneously for faster results
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-md hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-red-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaDatabase className="text-red-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Enterprise-Level Drake Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Designed for large CPA firms, offering advanced scalability, robust security features, and the capacity to handle high volumes of tax returns.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-lg hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>
                    </div>
                </div>
            </div>
        );
    };



    const TaxHostingWorks = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-6xl mx-auto p-8 pt-8 space-y-12 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    How Our Lacerte Hosting Works
                </h2>

                <div className="relative">
                    <div className="absolute left-1/2 top-0 transform -translate-x-1/2 h-full border-l-2 border-dashed border-gray-300" />
                    <div className="space-y-8">
                        {[
                            {
                                step: 'Consultation',
                                description: 'Our experts will assess your needs and recommend a tailored hosting solution for Lacerte Tax Software.',
                                icon: <FaComments className="w-10 h-10 text-blue-500" />,
                            },
                            {
                                step: 'Seamless Migration',
                                description: 'We’ll securely migrate your Lacerte Tax Software and associated data to our cloud platform without interrupting your operations',
                                icon: <FaExchangeAlt className="w-10 h-10 text-green-500" />,
                            },
                            {
                                step: 'Cloud Access',
                                description: 'Once hosted, you can access Lacerte from any device with an internet connection, enabling you to work remotely and efficiently.',
                                icon: <FaCloud className="w-10 h-10 text-purple-500" />,
                            },
                            {
                                step: 'Ongoing Management',
                                description: 'We continuously manage the technical aspects of your hosted environment, ensuring that your Lacerte experience is smooth, secure, and reliable.',

                                icon: <FaHeadset className="w-10 h-10 text-red-500" />,
                            },
                        ].map((step, index) => (
                            <div key={index} className="relative bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center space-y-4">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 flex items-center justify-center bg-white rounded-full border-4 border-gray-200">
                                    {step.icon}
                                </div>
                                <div className="mt-12">
                                    <h3 className="text-2xl font-semibold text-gray-800">{step.step}</h3>
                                    <p className="text-lg text-gray-600 mt-2">{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        );
    };


    const WhyChooseConciseNext = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const cardVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.6,
                    ease: "easeOut",
                    when: "beforeChildren",
                    staggerChildren: 0.3,
                },
            },
        };

        const iconVariants = {
            hidden: { scale: 0 },
            visible: {
                scale: 1,
                transition: {
                    duration: 0.5,
                    ease: "backOut",
                },
            },
        };

        return (
            <div className="bg-gray-50 py-5">
                <div className="max-w-7xl mx-auto px-6 sm:px-8">
                    <motion.h2
                        className="text-4xl font-bold text-center text-gray-800 mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Why Tax Professionals Choose ConciseNext for Lacerte Hosting
                    </motion.h2>

                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={cardVariants}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8"
                    >
                        {[
                            {
                                title: "Industry Expertise",
                                description:
                                    "ConciseNext specializes in hosting tax software like Lacerte, providing tailored solutions that meet the unique demands of tax professionals and accounting firms.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-blue-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaShieldAlt size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Scalable Solutions",
                                description:
                                    "Our hosting services are designed to grow with your business. Whether you’re an individual preparer or part of a large firm, we offer scalable resources to accommodate your needs during tax season and beyond.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-green-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaLayerGroup size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Robust Security",
                                description:
                                    "Protecting sensitive client data is our priority. Our secure cloud environment includes encryption, multi-factor authentication, and real-time monitoring to ensure your data is safe.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaTools size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Simplified IT Management",
                                description:
                                    "Let us handle the technical challenges, including backups, security updates, and server maintenance, so you can focus on what you do best—preparing taxes.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaTools size={48} />
                                    </motion.div>
                                ),
                            },
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-lg shadow-lg text-start flex flex-col items-center"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    };




    const TaxSoftwareVersion = () => {
        const containerVariants = {
            hidden: { opacity: 0, y: 20 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    staggerChildren: 0.3,
                    duration: 0.5,
                    ease: 'easeInOut',
                },
            },
        };

        const itemVariants = {
            hidden: { opacity: 0, y: 10 },
            visible: { opacity: 1, y: 0 },
        };

        const softwareList = [
            { name: 'Drake Tax Software', icon: FaDatabase },
            { name: 'Lacerte Tax Software', icon: FaCloud },
            { name: 'ProSeries Tax Software', icon: FaLock },
            { name: 'UltraTax CS', icon: FaTools },
            { name: 'ProSystem fx', icon: FaCloud },
        ];

        return (
            <div className="relative bg-blue-50 py-16 px-6">
                {/* Background Image */}
                <div className="absolute inset-0 bg-[url('https://concisenext.com/public_image/ai-generated-8799924_1280.webp')] bg-cover bg-center opacity-20"></div>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                    className="relative max-w-6xl mx-auto text-center bg-white p-8 rounded-lg shadow-lg"
                >
                    {/* Title Section */}
                    <motion.h2
                        variants={itemVariants}
                        className="text-4xl font-bold text-blue-900 mb-8"
                    >
                        Additional Supported Tax Software
                    </motion.h2>

                    {/* List of Tax Software with Icons */}
                    <motion.ul variants={containerVariants} className="space-y-4 text-left">
                        {softwareList.map((software, index) => (
                            <motion.li
                                key={index}
                                variants={itemVariants}
                                className="flex items-center text-lg text-blue-800 font-medium"
                            >
                                <software.icon className="text-blue-600 w-8 h-8 mr-4" />
                                {software.name}
                            </motion.li>
                        ))}
                    </motion.ul>

                    {/* Custom Hosting Solutions Section */}
                    <motion.p
                        variants={itemVariants}
                        className="mt-8 text-blue-700 text-lg"
                    >
                        If you need custom hosting solutions for any other tax software,{' '}
                        <span className="font-semibold underline hover:text-blue-900 cursor-pointer">
                            contact us
                        </span>{' '}
                        for personalized support.
                    </motion.p>

                    {/* Divider */}
                    <div className="mt-12">
                        <hr className="border-blue-300 mb-8" />

                        {/* TaxWise Hosting Section with Image */}
                        <motion.div variants={containerVariants} className="text-left">
                            <motion.h3
                                variants={itemVariants}
                                className="text-3xl font-bold text-blue-900 mb-4"
                            >
                                Experience the Benefits of Lacerte Hosting with ConciseNext
                            </motion.h3>
                            <div className="flex flex-col md:flex-row md:items-center">
                                <motion.p
                                    variants={itemVariants}
                                    className="text-lg text-blue-700 leading-relaxed md:w-1/2"
                                >
                                    By hosting Lacerte Tax Software in the cloud with ConciseNext, you can work from anywhere, collaborate more effectively, and ensure the security of your data. Our fully managed cloud hosting services are designed to optimize your tax preparation process, giving you the tools to succeed.
                                </motion.p>
                                {/* Image with transition */}
                                <motion.img
                                    src="https://concisenext.com/public_image/ai-generated-8799924_1280.webp"
                                    alt="TaxWise Hosting"
                                    className="rounded-lg shadow-lg w-full md:w-1/2 mt-6 md:mt-0 md:ml-8"
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5 }}
                                />
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        );
    };


    const ContactUs = () => {
        return (
            <section className="relative bg-gray-100 py-6 px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="absolute inset-0">
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 translate-y-1/4 -z-10" width="404" height="404" fill="none" viewBox="0 0 404 404">
                        <defs>
                            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-indigo-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#pattern1)" />
                    </svg>
                </div>
                <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <motion.h2
                        className="text-3xl font-extrabold text-gray-900 mb-6"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        Contact Us
                    </motion.h2>
                    <div className="flex flex-col items-center">
                        <motion.div
                            className="text-gray-800 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
                        >
                            <p className="text-lg font-semibold">📞 Call us: +1 (800) 123-4567</p>
                            <p className="text-lg font-semibold">📧 Email us: <a href="mailto:sales@ConciseNext.com" className="text-indigo-600 hover:underline">sales@ConciseNext.com</a></p>
                        </motion.div>
                        <motion.p
                            className="text-gray-500"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
                        >
                            ConciseNext – Secure, reliable, and cost-effective cloud hosting for Lacerte Tax Software
                        </motion.p>
                    </div>
                </div>
            </section>
        );
    };

    useEffect(() => {
        // Set the document title
        document.title = "Lacerte Tax Software Hosting || ConciseNext.com";

        // Create and set meta tags
        const metaDescription = document.createElement('meta');
        metaDescription.name = "description";
        metaDescription.content = "Enhance your tax preparation with ConciseNext’s UltraTax CS Hosting, providing secure, remote access for seamless collaboration.";
        document.head.appendChild(metaDescription);

        const metaKeywords = document.createElement('meta');
        metaKeywords.name = "keywords";
        metaKeywords.content = "concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management";
        document.head.appendChild(metaKeywords);

        const metaRobots = document.createElement('meta');
        metaRobots.name = "robots";
        metaRobots.content = "index, follow";
        document.head.appendChild(metaRobots);

        // Set the canonical link
        const canonicalLink = document.createElement('link');
        canonicalLink.rel = "canonical";
        canonicalLink.href = document.URL; // Adjust to the specific page
        document.head.appendChild(canonicalLink);

        // Open Graph tags
        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute("property", "og:title");
        ogTitle.content = "ConciseNext: Your Cloud Application Hosting Solution"; // Title reflecting your service
        document.head.appendChild(ogTitle);

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute("property", "og:description");
        ogDescription.content = "Discover seamless cloud application hosting for QuickBooks, Sage, Tax Software, Drake, and more. Reliable, secure, and tailored for your business needs.";
        document.head.appendChild(ogDescription);

        const ogImage = document.createElement('meta');
        ogImage.setAttribute("property", "og:image");
        ogImage.content = "https://concisenext.com/static/media/logocolor.481479ea418411f3e450.png"; // Replace with actual image URL
        document.head.appendChild(ogImage);

        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute("property", "og:url");
        ogUrl.content = document.URL; // Adjust to the specific page
        document.head.appendChild(ogUrl);


        // Cleanup function to remove meta tags on unmount
        return () => {
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);
            document.head.removeChild(canonicalLink);
            document.head.removeChild(ogTitle);
            document.head.removeChild(ogDescription);
            document.head.removeChild(ogImage);
            document.head.removeChild(ogUrl);
        };
    }, [])


    return (
        <div>
            <TaxHero />
            <TaxIntroSection />
            <TaxBenefits />
            <TaxHostingSolutions />
            <TaxHostingWorks />
            <WhyChooseConciseNext />
            <TaxSoftwareVersion />
            <ContactUs />


        </div>
    )

}