import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './transitionStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import notFound from '../assets/images/notfound.webp'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import RequestTrialForm from './RequestTrialForm'
import emailjs from 'emailjs-com';


const Price = () => {
  const yearDiscount = 12;
  const halfYearDiscount = 5;
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState(1);
  const [quickBooksOpen, setQuickBooksOpen] = useState(true);
  const [taxSoftwareOpen, setTaxSoftwareOpen] = useState(false);
  const [sageOpen, setSageOpen] = useState(false);
  const [isSharedPlan, setIsSharedPlan] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const quickBooksItems = [
    { type: 1, id: 'quickbooksPro', label: 'QuickBooks Pro', },
    { type: 1, id: 'quickbooksPremier', label: 'QuickBooks Premier' },
    { type: 1, id: 'quickbooksEnterprise', label: 'QuickBooks Enterprise' },
    { type: 1, id: 'quickbooksPOS', label: 'QuickBooks POS', disabled: isSharedPlan },
  ];

  const hostingItems = [
    { type: 2, id: 'drakeHosting', label: 'Drake Hosting', disabled: isSharedPlan },
    { type: 2, id: 'proSeriesHosting', label: 'ProSeries Hosting', disabled: isSharedPlan },
    { type: 2, id: 'ultraTaxHosting', label: 'UltraTax Hosting', disabled: isSharedPlan },
    { type: 2, id: 'lacerteHosting', label: 'Lacerte Hosting', disabled: isSharedPlan },
    { type: 2, id: 'taxWiseHosting', label: 'TaxWise Hosting', disabled: isSharedPlan },
  ];
  const sageItems = [
    { type: 3, id: 'sage50', label: 'Sage 50 Hosting', disabled: isSharedPlan },
    { type: 3, id: 'sage100', label: 'Sage 100 Hosting', disabled: isSharedPlan },
    { type: 3, id: 'sage300', label: 'Sage 300 Hosting', disabled: isSharedPlan },
    { type: 3, id: 'sage500', label: 'Sage 500 Hosting', disabled: isSharedPlan },
  ];

  // Pricing data for each item
  const pricingData = {
    quickbooksPro: { name: "QuickBooks Pro", price: 10 },
    quickbooksPremier: { name: "QuickBooks Premier", price: 10 },
    quickbooksEnterprise: { name: "QuickBooks Enterprise", price: 10 },
    quickbooksPOS: { name: "QuickBooks POS", price: 10 },
    drakeHosting: { name: "Drake Hosting", price: 10 },
    proSeriesHosting: { name: "ProSeries Hosting", price: 10 },
    ultraTaxHosting: { name: "UltraTax Hosting", price: 10 },
    lacerteHosting: { name: "Lercerte Hosting", price: 10 },
    taxWiseHosting: { name: "TaxWise Hosting", price: 10 },

    sage50: { name: "Sage 50 Hosting", price: 10 },
    sage100: { name: "Sage 100 Hosting", price: 10 },
    sage300: { name: "Sage 300 Hosting", price: 10 },
    sage500: { name: "Sage 500 Hosting", price: 10 },

  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const prepareEmailData = () => {
    const selectedItemDetails = selectedItems.map((itemKey, index) => {
      return {
        name: pricingData[itemKey].name
      };
    });

    return {
      users,
      selectedItems: selectedItemDetails,
      basePrice: getBasePrice(),
      totalPrice: calculateTotalPrice(),
      totalStorage: calculateTotalStorage(),
    };
  };





  useEffect(() => {
    // Set the document title
    document.title = "Quickbooks, Sage & Tax Software Hosting Pricing Plans || ConciseNext.com";

    // Create and set meta tags
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Enhance your tax preparation with ConciseNext’s UltraTax CS Hosting, providing secure, remote access for seamless collaboration.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management";
    document.head.appendChild(metaKeywords);

    const metaRobots = document.createElement('meta');
    metaRobots.name = "robots";
    metaRobots.content = "index, follow";
    document.head.appendChild(metaRobots);

    // Set the canonical link
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = "canonical";
    canonicalLink.href = document.URL; // Adjust to the specific page
    document.head.appendChild(canonicalLink);

    // Open Graph tags
    const ogTitle = document.createElement('meta');
    ogTitle.setAttribute("property", "og:title");
    ogTitle.content = "ConciseNext: Your Cloud Application Hosting Solution"; // Title reflecting your service
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.setAttribute("property", "og:description");
    ogDescription.content = "Discover seamless cloud application hosting for QuickBooks, Sage, Tax Software, Drake, and more. Reliable, secure, and tailored for your business needs.";
    document.head.appendChild(ogDescription);

    const ogImage = document.createElement('meta');
    ogImage.setAttribute("property", "og:image");
    ogImage.content = "https://concisenext.com/static/media/logocolor.481479ea418411f3e450.png"; // Replace with actual image URL
    document.head.appendChild(ogImage);

    const ogUrl = document.createElement('meta');
    ogUrl.setAttribute("property", "og:url");
    ogUrl.content = document.URL; // Adjust to the specific page
    document.head.appendChild(ogUrl);


    // Cleanup function to remove meta tags on unmount
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
      document.head.removeChild(metaRobots);
      document.head.removeChild(canonicalLink);
      document.head.removeChild(ogTitle);
      document.head.removeChild(ogDescription);
      document.head.removeChild(ogImage);
      document.head.removeChild(ogUrl);
    };
  }, [])


  const handleEmailSend = () => {
    const emailData = prepareEmailData();

    const templateParams = {
      selectedItems: emailData.selectedItems.map(item => `${item.name}`),
      forUser: emailData.users,
      totalStorage: emailData.totalStorage,

      basePrice: emailData.basePrice,
      totalAppPrice: emailData.totalPrice,
      monthlyTotalAmount: getTotalAmount(),
      halfYearlyTotalAmount: getDiscount(calculateHalfYearlyPrice(getTotalAmount()), halfYearDiscount),
      anualTotalAmout: getDiscount(calculateYearlyPrice(getTotalAmount()), yearDiscount),
      halfYearDiscount: halfYearDiscount,
      AnnualDiscount: yearDiscount,
    };

    // emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', templateParams, 'YOUR_USER_ID')
    //   .then((response) => {
    //     console.log('Email sent successfully!', response.status, response.text);
    //     // Optionally reset or provide feedback to the user
    //   })
    //   .catch((error) => {
    //     console.error('Failed to send email. Error:', error);
    //   });
  };






  // Function to handle item selection
  const handleItemSelection = (itemKey, type) => {
    if (selectedItems.includes(itemKey)) {
      setSelectedItems(selectedItems.filter((item) => item !== itemKey));
      setSelectedType(selectedType.filter((item) => item !== type));
    } else {
      setSelectedItems([...selectedItems, itemKey]);
      setSelectedType([...selectedType, type]);
    }
  };

  // Function to calculate total monthly price
  const calculateTotalPrice = () => {
    return selectedItems.reduce((total, item) => {
      return total + pricingData[item].price;
    }, 0);
  };

  // Function to calculate yearly and half-yearly prices
  const calculateYearlyPrice = (monthlyPrice) => {
    return monthlyPrice * 12;  // 12 months in a year
  };

  const calculateHalfYearlyPrice = (monthlyPrice) => {
    return monthlyPrice * 6;  // 6 months in half a year
  };

  // User Details
  const userDetails = {
    defaultStoragePerUser: 6, // Default storage per user in GB
  };

  // Calculate storage based on the number of users
  const calculateTotalStorage = () => {
    if (isSharedPlan) {
      return userDetails.defaultStoragePerUser * users; // Total storage in GB
    } else {
      if (users >= 5) {
        const extraOfferStorage = 0;
        return (userDetails.defaultStoragePerUser + extraOfferStorage) * users; // Total storage in GB
      }
      return userDetails.defaultStoragePerUser * users; // Total storage in GB
    }
  };


  const getDiscount = (price, discount) => {
    const discountAmount = (price * discount) / 100; // Calculate the discount amount
    const finalPrice = price - discountAmount; // Subtract the discount from the original price
    return finalPrice.toFixed(2); // Return the final price after discount
  };


  // Function to switch plans and reset selections
  const switchPlan = (isShared) => {
    setIsSharedPlan(isShared);
    setSelectedItems([]); // Clear selected items when switching plans
    setSelectedType([]); // Clear selected items when switching plans
    setQuickBooksOpen(true);
    setTaxSoftwareOpen(false);
    setSageOpen(false);
    setUsers(1)
  };

  const getBasePrice = () => {
    let basePrice;
    if (isSharedPlan) {
      basePrice = 33;
      return basePrice * users
    } else {
      if (users >= 5) {
        basePrice = 199;
        const perUserPrice = 29;
        if (users > 5) {
          return basePrice + (perUserPrice * (users - 5))
        }
        return basePrice
      } else {
        basePrice = 99;
        const perUserPrice = 34;
        if (users > 2) {
          return basePrice + (perUserPrice * (users - 2))
        }
        return basePrice
      }

    }
  }

  const getCurrentProductPrice = (price, index, itemKey) => {
    if (isSharedPlan) {
      if (index > 0) {
        return `$${price}.00`;
      }
      if (index == 0) {
        pricingData[itemKey].price = 0;
        return <span><s>${price}.00</s> $0.00</span>;
      }
    } else {
      if (index > 1) {
        return `$${price}.00`;
      }
      if (index <= 1) {
        pricingData[itemKey].price = 0;
        return <span><s>${price}.00</s> $0.00</span>;
      }
    }
  }


  const getTotalAmount = () => {
    return calculateTotalPrice() + getBasePrice();
  }




  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center pb-5 bg-gred">
      <div
        className="w-full h-[100vh] bg-cover bg-center text-white flex flex-col items-center justify-center relative"
        style={{ filter: "blur(10px)", backgroundImage: "url('https://cdn.pixabay.com/photo/2016/07/02/22/27/european-union-1493894_1280.jpg')" }}
      >
      </div>

      <div className="w-full max-w-7xl bg-gray-800 shadow-lg rounded-lg p-3 md:p-12 trial-from2">
        {/* Plan Toggle */}
        <div className="flex md:flex-row justify-start items-center mb-0 ml-1">
          <button
            className={`text-lg font-bold px-6 py-2 rounded-tl-full transition-transform ${isSharedPlan ? 'bg-gray-700 text-white transform scale-105' : 'bg-[#eee] text-[#000]'}`}
            onClick={() => switchPlan(true)}
          >
            {isSharedPlan && '✓'} Shared Plan
          </button>
          <button
            className={`text-lg font-bold px-6 py-2 rounded-tr-full transition-transform ${!isSharedPlan ? 'bg-gray-700 text-white transform scale-105' : 'bg-[#eee] text-[#000]'}`}
            onClick={() => switchPlan(false)}
          >
            {!isSharedPlan && '✓'}  Dedicated Plan
          </button>
        </div>

        <div className="flex flex-col md:flex-row">
          {/* Sidebar */}
          <div className="w-full md:w-1/3 border-r border-gray-700 pr-4 md:pr-0">
            {/* QuickBooks Section */}
            <div className="bg-[#00000040] p-3">
              <div
                className="flex justify-between items-center cursor-pointer px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600"
                onClick={() => setQuickBooksOpen(!quickBooksOpen)}
              >
                <h3 className="text-xl font-semibold text-white">QuickBooks</h3>
                <FontAwesomeIcon
                  icon={quickBooksOpen ? faChevronUp : faChevronDown}
                  className="text-gray-400"
                />
              </div>
              <CSSTransition
                in={quickBooksOpen}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <ul className="transition-all duration-300 ease-in-out text-gray-300 my-3 ml-3">
                  {quickBooksItems.map((item) => (
                    <li className={item.disabled ? "mb-2 text-gray-300" : "mb-2 text-white"} key={item.id}>
                      <label className={item.disabled ? "flex items-center cursor-not-allowed" : "flex items-center cursor-pointer"}>
                        <input
                          type="checkbox"
                          checked={selectedItems.includes(item.id)}
                          disabled={item.disabled}
                          className={item.disabled ? "mr-2 bg-slate-300" : "mr-2"}
                          onChange={() => handleItemSelection(item.id, item.type)}
                          name={item.id}
                        />
                        {item.label}
                      </label>
                    </li>
                  ))}
                </ul>
              </CSSTransition>
            </div>

            {/* Tax Software Section */}
            <div className="bg-[#00000040] p-3">
              <div
                className="flex justify-between items-center cursor-pointer px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600"
                onClick={() => setTaxSoftwareOpen(!taxSoftwareOpen)}
              >
                <h3 className="text-xl font-semibold text-white">Tax Software</h3>
                <FontAwesomeIcon
                  icon={taxSoftwareOpen ? faChevronUp : faChevronDown}
                  className="text-gray-400"
                />
              </div>
              <CSSTransition
                in={taxSoftwareOpen}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <ul className="transition-all duration-300 ease-in-out text-gray-300 my-3 ml-3">
                  {hostingItems.map(({ id, label, disabled, type }) => (
                    <li key={id} className={disabled ? "mb-2 text-gray-300" : "mb-2 text-white"}>
                      <label className={disabled ? "flex items-center cursor-not-allowed" : "flex items-center cursor-pointer"}>
                        <input
                          checked={selectedItems.includes(id)}
                          disabled={disabled}
                          type="checkbox"
                          className="mr-2"
                          onChange={() => handleItemSelection(id, type)}
                        />
                        {label}
                      </label>
                    </li>
                  ))}
                </ul>
              </CSSTransition>
            </div>

            {/* Sage Section */}
            <div className="mb-6 bg-[#00000040] p-3">
              <div
                className="flex justify-between items-center cursor-pointer px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600"
                onClick={() => setSageOpen(!sageOpen)}
              >
                <h3 className="text-xl font-semibold text-white">Sage</h3>
                <FontAwesomeIcon
                  icon={sageOpen ? faChevronUp : faChevronDown}
                  className="text-gray-400"
                />
              </div>
              <CSSTransition
                in={sageOpen}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <ul className="transition-all duration-300 ease-in-out text-gray-300 my-3 ml-3">
                  {sageItems.map(({ id, label, disabled, type }) => (
                    <li key={id} className={disabled ? "mb-2 text-gray-300" : "mb-2 text-white"}>
                      <label className={disabled ? "flex items-center cursor-not-allowed" : "flex items-center cursor-pointer"}>
                        <input
                          checked={selectedItems.includes(id)}
                          disabled={disabled}
                          type="checkbox"
                          className="mr-2"
                          onChange={() => handleItemSelection(id, type)}
                        />
                        {label}
                      </label>
                    </li>
                  ))}
                </ul>
              </CSSTransition>
            </div>
          </div>

          {/* Pricing and Plan Details */}
          <div className="w-full md:w-2/3">
            {!selectedItems.length ?
              <div className='flex items-center justify-center'>
                <img src={notFound} />
              </div>
              :
              <div className='bg-[#00000040] p-4'>
                <div className="flex sm:flex-col md:flex-row justify-between items-center mb-2  border-b pb-2">
                  <h3 className="text-xl font-semibold text-white">Number of Users</h3>

                  <div className="flex items-center sm:mt-4 md:mt-0">
                    <button
                      className="bg-gray-700 text-white px-4 py-2 rounded-l-full hover:bg-gray-600 transition"
                      onClick={() => setUsers(users > 1 ? users - 1 : 1)}
                    >
                      &minus;
                    </button>
                    <span className="px-4 py-2 bg-gray-500 text-white">{users}</span>
                    <button
                      className="bg-gray-700 text-white px-4 py-2 rounded-r-full hover:bg-gray-600 transition"
                      onClick={() => setUsers(users + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>

                {/* Selected Items */}
                <div className="mb-8">
                  <h3 className="text-xl font-bold text-white">Selected Items</h3>
                  <table className="min-w-full text-gray-400">
                    <thead>
                      <tr className="bg-gray-800">
                        <th className="text-left py-2 px-4">Item</th>
                        <th className="text-right py-2 px-4">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedItems.map((itemKey, index) => (
                        <tr key={itemKey} className={index % 2 === 0 ? "bg-gray-300" : "bg-gray-400"}>
                          <td className="py-2 px-4 text-dark">{pricingData[itemKey].name}</td>
                          <td className="py-2 px-4 text-right text-dark">{getCurrentProductPrice(pricingData[itemKey].price, index, itemKey)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* User and Storage Details */}
                <div className="p-6 bg-gray-800 rounded-lg shadow-lg border-b">
                  <h3 className="text-xl font-bold text-white border-b pb-2 mb-4">User Details</h3>
                  <ul className="text-gray-300">
                    <div className='bg-[#051923] cust-border  px-3'>
                      <li className="flex justify-between py-2">
                        <span>Number of Users:</span>
                        <span className="font-semibold">{users}</span>
                      </li>
                      <li className="flex justify-between py-2">
                        <span>Total Storage:</span>
                        <span className="font-semibold">{calculateTotalStorage()} GB</span>
                      </li>
                    </div>
                    <li className="flex justify-between py-2 px-3">
                      <span>Base Price:</span>
                      <span className="font-semibold">${getBasePrice()}.00</span>
                    </li>
                    <li className="flex justify-between py-2 px-3">
                      <span>Application Total Price:</span>
                      <span className="font-semibold">${calculateTotalPrice()}.00</span>
                    </li>
                  </ul>
                </div>

                <div className="bg-gray-800 p-6 rounded-lg shadow-lg relative">
                  {/* Yearly Price */}
                  <div className="flex justify-between items-center">
                    <h4 className="text-lg font-semibold text-white">Monthly Price</h4>
                    <span className="font-semibold text-green-500">${getTotalAmount()}.00</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <h4 className="text-lg font-semibold text-white">Half-yearly Price</h4>
                    <span className="text-white text-lg">
                      <span className="flex items-center">
                        <span className="text-white-600 mx-2 text-sm border rounded-full bg-red-500 px-2">${halfYearDiscount}% Off</span>
                        <span className="line-through text-gray-400 mr-2">${calculateHalfYearlyPrice(getTotalAmount())}.00</span>
                        <span className="font-semibold text-green-500">${getDiscount(calculateHalfYearlyPrice(getTotalAmount()), halfYearDiscount)}</span>
                      </span>
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <h4 className="text-lg font-semibold text-white">Annual Price</h4>
                    <span className="text-white text-lg">
                      <span className="flex items-center">
                        <span className="text-white-600 mx-2 text-sm border rounded-full bg-red-500 px-2">${yearDiscount}% Off</span>
                        <span className="line-through text-[13px] xl:text-[16px] text-gray-400 mr-2">${calculateYearlyPrice(getTotalAmount())}.00</span>
                        <span className="font-semibold  text-green-500">${getDiscount(calculateYearlyPrice(getTotalAmount()), yearDiscount)}</span>
                      </span>
                    </span>
                  </div>
                </div>

                {/* Buttons */}
                <div className="mt-8 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                  <button onClick={() => setOpen(true)} className="bg-gray-700 text-white px-6 py-3 rounded-full hover:bg-green-500 transition">
                    Request A Free Quote
                  </button>
                  <a href='tel:+xxxxxxxxx' className="border border-teal-500 text-teal-500 px-6 py-3 bg-primary text-white rounded-full hover:bg-teal-500 hover:text-white transition">
                    Contact Sales
                  </a>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <Dialog open={open} fullWidth={true} maxWidth="md" onClose={handleClose} >
        <button
          onClick={handleClose}
          className="absolute top-0 right-0 text-xl bg-red-500 rounded-bl px-3 py-2 font-bold text-white"
          aria-label="close"
        >
          <h2>X</h2>
        </button>
        <div className='p-8'>
          <RequestTrialForm />

        </div>
      </Dialog>
    </div>
  );

};

export default Price;
