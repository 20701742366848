import React, { useEffect, useRef } from 'react';
import { ShieldCheckIcon, GlobeAltIcon, ClockIcon, ChartBarIcon, SupportIcon, CloudIcon } from '@heroicons/react/solid';
import { motion, useAnimation, useTransform, useScroll } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import dataSec from "../assets/images/datasecurity.webp";
import multiUsr from "../assets/images/multiUsr.webp"
import accdata from "../assets/images/accesdata.webp"
import dataBckp from "../assets/images/dataBackup.webp"
import relper from "../assets/images/relper.webp"
import techsuport from "../assets/images/techsup.webp"
import teamwrk from "../assets/images/teamwrk.webp"
import enterprise2 from "../assets/images/qb/enterprise.webp"
import etertprisefirst from "../assets/images/enterprisefirst.webp"
import enterprisesec from "../assets/images/enterprisesecu.webp"
import enterpriserem from "../assets/images/enterpriseremote.webp"
import enterpriseavl from "../assets/images/enterpriserelibilities.webp"
import enterprisesup from "../assets/images/enterprisesup.webp"

import { FaUser, FaUsers, FaHdd, FaMemory, FaAppStore } from 'react-icons/fa';

const features = [
  {
    id: 1,
    name: 'Superior Remote Access',
    icon: ShieldCheckIcon,
    description: 'With ConciseNext’s QuickBooks Enterprise hosting, you can access your accounting data from any location, on any device. Whether you’re at your desk or on the go, our cloud hosting enables seamless access to your critical business information, helping you stay productive no matter where you are.',
    image: enterpriserem,
  },
  {
    id: 2,
    name: 'Advanced Security Protocols',
    icon: GlobeAltIcon,
    description: 'Protect your sensitive financial data with our top-of-the-line security measures. ConciseNext utilizes sophisticated encryption, multi-factor authentication, and secure data centers to ensure that your information is safeguarded against unauthorized access and cyber threats.',
    image: enterprisesec,
  },
  {
    id: 3,
    name: 'High Availability and Reliability',
    icon: ClockIcon,
    description: 'Experience uninterrupted service with ConciseNext’s 99.9% uptime guarantee. Our QuickBooks Enterprise hosting infrastructure is built for reliability, ensuring your business operations run smoothly without the risk of downtime that could disrupt your workflow.',
    image: enterpriseavl,
  },
  {
    id: 4,
    name: 'Scalable Hosting Solutions',
    icon: ChartBarIcon,
    description: 'As your business grows, your hosting needs may evolve. ConciseNext offers scalable QuickBooks Enterprise hosting solutions that can easily adapt to your expanding requirements, allowing you to add users, increase storage, or upgrade resources without any hassle.',
    image: enterprisesup,
  },
  {
    id: 11,
    name: 'Streamlined Collaboration',
    icon: SupportIcon,
    description: 'Facilitate better teamwork with our cloud-based QuickBooks Enterprise hosting. ConciseNext’s hosting allows multiple users to work on the same data files simultaneously, improving collaboration and enhancing your team’s productivity.',
    image: teamwrk,
  },
  {
    id: 16,
    name: 'Cost-Efficient and Hassle-Free',
    icon: CloudIcon,
    description: 'Save on IT costs and eliminate the need for expensive on-premise servers with ConciseNext’s cost-effective hosting solutions. Our QuickBooks Enterprise hosting allows you to focus on your core business activities while we manage the technical infrastructure.',
    image: relper,
  },
];





const BenefitsSection = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });

  return (
    <div className="bg-white py-10">
      <div className="container mx-auto px-6">
        <h2 className={`text-3xl font-bold text-center mb-6 ${inView1 ? 'animate-fadeIn' : ''}`}>
          Key Benefits of QuickBooks Hosting with ConciseNext
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          <div
            ref={ref1}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView1 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Expert Support, Anytime You Need It</h3>
            <p className="mb-4 card-info">
              Our dedicated support team is available 24/7 to assist you with any issues or inquiries. ConciseNext’s QuickBooks Enterprise specialists are committed to providing you with prompt and reliable support, ensuring your business operations continue without interruption
            </p>
            <img
              src={techsuport}
              alt="Accessibility"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref2}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView2 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Cost-Effective Hosting Solutions</h3>
            <p className="mb-4 card-info">
              Reduce your IT expenses with ConciseNext’s affordable QuickBooks Enterprise hosting. By hosting your QuickBooks Enterprise in the cloud, you eliminate the need for costly in-house servers and IT maintenance, allowing you to focus on what matters most—growing your business.
            </p>
            <img
              src={dataBckp}
              alt="Backup and Recovery"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref3}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView3 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Multi-User Collaboration</h3>
            <p className="mb-4 card-info">
              Collaborate with your team in real-time. With QuickBooks hosting by ConciseNext, multiple users can access and work on the same files simultaneously.
            </p>
            <img
              src={multiUsr}
              alt="Collaboration"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HostingSection = () => {
  return (
    <div className="bg-gray-100 py-6 px-4 md:py-20 md:px-6">
      {/* Container */}
      <div className="container mx-auto max-w-7xl">
        {/* Header Section */}
        <div className="text-center mb-6 md:mb-16">
          <h1 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-3 md:mb-4">
            QuickBooks Enterprise Hosting by ConciseNext
          </h1>
          <p className="text-base md:text-lg text-gray-600">
            Empower Your Business with Advanced Cloud Solutions
          </p>
        </div>
        {/* Content Section */}
        <div className="grid gap-10 md:gap-16 items-center grid-cols-1 md:grid-cols-2">
          {/* Text Content */}
          <div className="space-y-4 md:space-y-6">
            <p className="text-base md:text-xl text-gray-700 leading-relaxed">
              ConciseNext offers robust QuickBooks Enterprise hosting services, designed to meet the complex needs of large businesses and accounting professionals. Our cloud hosting solutions provide a secure, scalable, and accessible environment for your QuickBooks Enterprise software, ensuring you can manage your business operations with greater efficiency and flexibility.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="flex items-center space-x-2">
                <ShieldCheckIcon className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <span className="font-semibold text-gray-800">Secure</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-6 w-6 md:h-8 md:w-8 text-green-600" />
                <span className="font-semibold text-gray-800">Reliable</span>
              </div>
              <div className="flex items-center space-x-2">
                <CloudIcon className="h-6 w-6 md:h-8 md:w-8 text-purple-600" />
                <span className="font-semibold text-gray-800">Scalable</span>
              </div>
            </div>
          </div>
          {/* Image */}
          <div className="relative">
            <img
              src={enterprise2}
              alt="Cloud Hosting"
              className="rounded-lg shadow-lg transform hover:scale-105 transition duration-500 h-60 md:h-80"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuickBooksProHostingIntro = () => {
  return (
    <section className="bg-gradient-to-r from-blue-800 via-blue-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          QuickBooks Enterprise Hosting Pricing & Plans
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          At <span className="font-bold">ConciseNext</span>, we offer flexible hosting plans designed to meet the unique needs of businesses of all sizes. Whether you're an individual user or part of a growing team, our plans provide the performance, security, and scalability you need to keep your accounting operations running smoothly.
        </motion.p>
      </div>
    </section>
  );
};

const StandardHostingPlan = () => {
  const standardPlans = [
    {
      title: '1-User Plan',
      price: '$33',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-purple-100',
    },
    {
      title: 'Per Additional User',
      price: '$30',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-pink-100',
    }
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Standard Hosting Plans</h2>

        <div className="flex flex-wrap justify-center gap-6">
          {standardPlans.map(plan => (
            <motion.div
              key={plan.title}
              className={`${plan.color} p-6 rounded-3xl shadow-lg border border-gray-200 w-full sm:w-[48%] md:w-[30%] lg:w-[18%] h-[250px] flex flex-col justify-between`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.3 }}
            >
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2 text-gray-800">{plan.title}</h3>
                <div className="text-3xl font-bold text-gray-800 mb-1">{plan.price}</div>
                <div className="text-lg text-gray-600 mb-4">{plan.description}</div>
              </div>
              <div className="flex items-center justify-center text-gray-600">
                <FaHdd className="text-blue-600 mr-2" />
                <span>{plan.storage}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const DifferenceSection = () => {
  return (
    <div className="relative py-20 px-6">
      {/* Background Effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-800 via-blue-500 to-purple-300 opacity-1 "></div>
      {/* Content */}
      <div className="relative container mx-auto max-w-5xl text-center text-white">
        <h2 className="text-4xl font-extrabold mb-6">Unlock the Full Potential of QuickBooks Enterprise with ConciseNext</h2>
        <p className="text-lg leading-relaxed mb-8">
          By choosing ConciseNext for your QuickBooks Enterprise hosting, you’re partnering with a provider that understands the unique needs of large businesses. Our advanced cloud hosting solutions are designed to help you maximize the capabilities of QuickBooks Enterprise, driving your business success.
        </p>
        <button className="bg-white text-button font-semibold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition transform hover:scale-105">
          Learn More
        </button>
      </div>
    </div>
  );
};

const SmoothScrollFeature = ({ feature, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });

  useEffect(() => {
    if (inView && window.innerWidth > 768) {  // Check if screen is larger than 768px
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={window.innerWidth > 768 ? controls : 'visible'}  // Disable animation on small screens
      variants={{
        hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
        visible: { opacity: 1, x: 0 },
      }}
      transition={{ duration: 0.7, ease: 'easeOut' }}
      className="grid md:grid-cols-2 gap-8 xl:gap-20 items-center py-8"
    >
      {/* Image */}
      <div className={`${index % 2 === 0 ? 'order-last md:order-first' : ''}`}>
        <img
          src={feature.image}
          alt={feature.name}
          className="rounded-lg shadow-lg transform hover:scale-105 h-[auto] w-[400px] xl:h-[auto] xl:w-[600px] transition duration-500"
        />
      </div>
      {/* Text */}
      <div>
        <h3 className="text-2xl font-bold text-gray-800 mb-4">{feature.name}</h3>
        <p className="text-lg text-gray-700 leading-relaxed">{feature.description}</p>
      </div>
    </motion.div>
  );
};


const Hero = () => {
  const { scrollY } = useScroll();
  const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
  const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
  const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
  const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

  return (
    <div className="relative h-[500px] md:h-[600px]  bg-[url('https://cdn.pixabay.com/photo/2015/01/08/18/25/desk-593327_1280.jpg')] bg-cover bg-center bg-no-repeat overflow-hidden">
      {/* Background Circles Effect */}
      <div className="absolute flex items-center justify-center">
        <motion.div
          className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
          style={{ scale: scale1 }}
        />
        <motion.div
          className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
          style={{ scale: scale2 }}
        />
        <motion.div
          className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
          style={{ scale: scale3 }}
        />
      </div>

      {/* Hero Content */}
      <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
        <motion.h1
          className="text-3xl sm:text-4xl md:text-3xl font-bold mb-2 md:mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Transform Your Accounting Workflow with ConciseNext’s Elite QuickBooks Enterprise Hosting
        </motion.h1>
        <motion.p
          className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
        >
          Discover Unrivaled Security, Reliability, and Flexibility Tailored for Accounting Professionals
        </motion.p>
        <motion.a
          href="/free-trial"
          className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
        >
          Learn More
        </motion.a>
      </div>



    </div>
  );
};

const QuickBooksEnterprise = () => {


  useEffect(() => {
    // Set the document title
    document.title = "Quickbooks Enterprise Hosting || ConciseNext.com";

    // Create and set meta tags
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Transform Your Accounting Workflow with ConciseNext’s Elite QuickBooks Enterprise Hosting";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "concisenext, quickbook, sage, drake, accounting software, business management, financial solutions, bookkeeping tools, invoicing software, tax preparation, payroll management";
    document.head.appendChild(metaKeywords);

    const metaRobots = document.createElement('meta');
    metaRobots.name = "robots";
    metaRobots.content = "index, follow";
    document.head.appendChild(metaRobots);

    // Set the canonical link
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = "canonical";
    canonicalLink.href = document.URL; // Adjust to the specific page
    document.head.appendChild(canonicalLink);

    // Open Graph tags
    const ogTitle = document.createElement('meta');
    ogTitle.setAttribute("property", "og:title");
    ogTitle.content = "ConciseNext: Your Cloud Application Hosting Solution"; // Title reflecting your service
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.setAttribute("property", "og:description");
    ogDescription.content = "Discover seamless cloud application hosting for QuickBooks, Sage, Drake, and more. Reliable, secure, and tailored for your business needs.";
    document.head.appendChild(ogDescription);

    const ogImage = document.createElement('meta');
    ogImage.setAttribute("property", "og:image");
    ogImage.content = "https://concisenext.com/static/media/logocolor.481479ea418411f3e450.png"; // Replace with actual image URL
    document.head.appendChild(ogImage);

    const ogUrl = document.createElement('meta');
    ogUrl.setAttribute("property", "og:url");
    ogUrl.content = document.URL; // Adjust to the specific page
    document.head.appendChild(ogUrl);


    // Cleanup function to remove meta tags on unmount
    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
      document.head.removeChild(metaRobots);
      document.head.removeChild(canonicalLink);
      document.head.removeChild(ogTitle);
      document.head.removeChild(ogDescription);
      document.head.removeChild(ogImage);
      document.head.removeChild(ogUrl);
    };
  }, []); // Empty array means this runs on mount only

  return (
    <div>
      <Hero />
      <HostingSection />
      <div className="bg-white ">
        <div className="container mx-auto max-w-7xl">
          {features.map((feature, index) => (
            <SmoothScrollFeature key={feature.id} feature={feature} index={index} />
          ))}
        </div>
      </div>

      <DifferenceSection />
      <BenefitsSection />

    </div>
  );
};

export default QuickBooksEnterprise;
