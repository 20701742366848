import React from 'react';
import { Link } from "react-router-dom";
import logo from "./../../assets/images/logo/logoi.png";

const Footer = () => {
    return (
        <footer>
            <div className="bg-[#343a40] text-white py-6 lg:py-10">
                <div className=" mx-auto text-center px-4">
                    <h2 className="text-2xl lg:text-4xl xl:text-5xl font-semibold mb-4">Ready to Get Started?</h2>
                    <p className="text-base lg:text-lg xl:text-xl mb-8">
                        Join countless other businesses that have transformed their operations with our reliable cloud hosting services.
                    </p>
                    <Link to={"/free-trial"} className="bg-button text-white py-3 px-6 rounded-lg text-lg lg:text-xl transition-colors">
                        Start Your Free Trial
                    </Link>
                </div>
            </div>

            <div className="bg-gray-900 text-white  px-4 py-6 lg:py-10">
                <div className=" grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
                    {/* Company Information */}
                    <div className=" flex items-center flex-col xl:flex-row justify-between mb-6 lg:mb-0">
                        <div>
                            <Link to={"/"}>
                                <img src={logo} className="h-12 xl:h-20 w-auto rounded-xl" alt="ConciseNext Logo" />
                            </Link>
                            <p className="text-base lg:text-lg mb-2">
                                <span className="font-bold">Address:</span> ConciseNext, Suite 500, Tech City, TX 75001
                            </p>
                            <p className="text-base lg:text-lg mb-2">
                                <span className="font-bold">Contact:</span> support@concisenext.com
                            </p>
                            <p className="text-base lg:text-lg mb-2">
                                <span className="font-bold">Phone:</span> +1 (123) 456-7890
                            </p>
                        </div>
                        <div className="mb-6 mt-5 lg:mb-0 lg:mt-10">
                            <h3 className="text-xl lg:text-2xl font-semibold mb-4">Empowering Your Cloud Journey</h3>
                            <p className="text-base lg:text-lg">
                                <span className="font-bold">Reliable Cloud Hosting:</span> Scaling your business with unparalleled performance, security, and flexibility.
                            </p>
                        </div>
                    </div>

                    {/* Quick Links */}
                    <div className="mb-6">
                        <h3 className="text-xl lg:text-2xl font-semibold mb-4 text-center">Quick Links</h3>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                            {[
                                {
                                    title: "General Links",
                                    links: [
                                        { name: "Home", path: "/" },
                                        { name: "Contact Us", path: "/contact-us" },
                                        { name: "Price", path: "/price" },
                                    ],
                                },
                                {
                                    title: "QuickBooks Hosting",
                                    links: [
                                        { name: "QuickBooks Hosting", path: "/quickbooks-hosting" },
                                        { name: "QuickBooks Pro Hosting", path: "/quickbooks-pro-hosting" },
                                        { name: "QuickBooks Premier Hosting", path: "/quickbooks-premier-hosting" },
                                        { name: "QuickBooks Enterprise Hosting", path: "/quickbooks-enterprise-hosting" },
                                        { name: "QuickBooks Add-ons Hosting", path: "/quickbooks-add-ons-hosting" },
                                        { name: "QuickBooks POS Hosting", path: "/quickbooks-pos-hosting" },
                                    ],
                                },
                                {
                                    title: "Sage Hosting",
                                    links: [
                                        { name: "Sage Hosting", path: "/sage-hosting" },
                                        { name: "Sage 50 Hosting", path: "/sage-50-hosting" },
                                        { name: "Sage 100 ERP Hosting", path: "/sage-100-hosting" },
                                        { name: "Sage 300 ERP Hosting", path: "/sage-300-hosting" },
                                        { name: "Sage 500 ERP Hosting", path: "/sage-500-hosting" },
                                    ],
                                },
                                {
                                    title: "Tax Software Hosting",
                                    links: [
                                        { name: "Tax Software Hosting", path: "/tax-hosting" },
                                        { name: "Drake Tax Software Hosting", path: "/tax-drake-hosting" },
                                        { name: "TaxWise Hosting", path: "/tax-wise-hosting" },
                                        { name: "UltraTax CS Hosting", path: "/ultra-tax-cs" },
                                        { name: "Lacerte Hosting", path: "/lacerte-hosting" },
                                        { name: "ProSeries Tax Software Hosting", path: "/pro-series-hosting" },
                                    ],
                                },
                                {
                                    title: "Business Solutions",
                                    links: [
                                        { name: "Small Business", path: "/small-business" },
                                        { name: "Law Firm Hosting", path: "/law-firm" },
                                        { name: "Virtual Desktop", path: "/virtual-desktop" },
                                        { name: "Managed IT Services", path: "/managed-it-services" },
                                    ],
                                },
                            ].map((category, index) => (
                                <div key={index} className="mb-4">
                                    <h4 className="font-semibold mb-2">{category.title}</h4>
                                    <ul className="space-y-2">
                                        {category.links.map((link, i) => (
                                            <li key={i}>
                                                <Link to={link.path} className="text-gray-400 hover:text-gray-200">{link.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Footer Bottom */}
                <div className="border-t border-gray-700 pt-4 mt-8 text-center px-4">
                    <p className="text-sm lg:text-base text-gray-400">&copy; {new Date().getFullYear()} ConciseNext.com. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
